import { APICore } from "../../helpers/api/apiCore";
import {
  setCreateStationModal, setStationError,
  setStationLoading, setStations
} from "./actions";


export interface StationData {
  id?: string;
  name: string;
  ln: string | null;
  lt: string | null;
}

/**
 * Load cities thunk.
 *
 * @param param0
 * @returns
 */
export const loadStationsThunk = () => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setStationLoading(true));

      const resp = await api.get("/stations");

      if (resp.data?.status === "OK") {
        dispatch(setStations(resp.data.result));
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setStationError(err));
    }
  };
};

/**
 * Create city thunk.
 *
 * @param param0
 * @returns
 */
export const createStationThunk = (data: StationData) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const url = data.id ? `/admin/stations/${data.id}` : "/admin/stations";
      const resp = data.id ? await api.update(url, data) : await api.create(url, data);

      if (resp.data) {
        dispatch(loadStationsThunk());
        dispatch(setCreateStationModal(false));
      }
    } catch (error: any) {
      console.log({ error });

      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setStationError(err));
    }
  };
};

// export const createOrUpdateServiceOptions = ({}: ServiceOptionsData) => {};
