import { User } from "../../types";

export enum USERS_ACTIONS {
  USERS_LOADED_SUCCESS = "USERS_LOADED_SUCCESS",
  USERS_LOADED_FAILED = "USERS_LOADED_FAILED",
  USERS_LOADING = "USERS_LOADING",
  USERS_DELETED_LOADING = "USERS_DELETED_LOADING",
}

export interface userState {
  users: User[];
  isLoading: boolean;
  isDeletedLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: userState = {
  users: [],
  isLoading: false,
  isDeletedLoading: false,
  error: null,
};

export interface UserActionType {
  type: keyof typeof USERS_ACTIONS;
  payload: any;
}

const JobReducer = (
  state: userState = initialState,
  action: UserActionType
): userState => {
  switch (action.type) {
    case USERS_ACTIONS.USERS_LOADED_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      };

    case USERS_ACTIONS.USERS_LOADED_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case USERS_ACTIONS.USERS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case USERS_ACTIONS.USERS_DELETED_LOADING:
      return {
        ...state,
        isDeletedLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default JobReducer;
