import { Station } from "../../types";

export enum STATIONS_ACTIONS {
  STATIONS_LIST = "STATIONS_LIST",
  STATIONS_ERROR = "STATIONS_ERROR",
  STATIONS_OPTIONS_MODAL = "STATIONS_OPTIONS_MODAL",
  STATIONS_UPDATE_MODAL = "STATIONS_UPDATE_MODAL",
  STATIONS_CREATE_MODAL = "STATIONS_CREATE_MODAL",
  STATIONS_LOADING = "STATIONS_LOADING",
}

export interface StationState {
  stations: Station[];
  showCreateModal: boolean;
  showUpdateModal: boolean;
  isLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: StationState = {
  stations: [],
  showCreateModal: false,
  showUpdateModal: false,
  isLoading: false,
  error: null,
};

export interface StationActionType {
  type: keyof typeof STATIONS_ACTIONS;
  payload: any;
}

const StationReducer = (
  state: StationState = initialState,
  action: StationActionType
): StationState => {
  switch (action.type) {
    case STATIONS_ACTIONS.STATIONS_LIST:
      return {
        ...state,
        stations: action.payload,
        isLoading: false,
      };

    case STATIONS_ACTIONS.STATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case STATIONS_ACTIONS.STATIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case STATIONS_ACTIONS.STATIONS_CREATE_MODAL:
      return {
        ...state,
        showCreateModal: action.payload,
      };

    case STATIONS_ACTIONS.STATIONS_UPDATE_MODAL:
      return {
        ...state,
        showUpdateModal: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default StationReducer;
