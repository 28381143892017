import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { FormInput } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { VehicleState } from "../../redux/vehicle/reducers";
import { createVehicleThunk, loadGearBoxes, loadVehicleAvailblePerStation, loadVehicleImmatriculation, updateVehicleThunk } from "../../redux/vehicle/thunk";
import { VehicleTypestate } from "../../redux/vehicleTypes/reducers";
import { Contravention, Reservation, Vehicle, VehicleInstance, VehicleType, } from "../../types";
import { StationState } from '../../redux/stations/reducers';
import { loadStationsThunk } from '../../redux/stations/thunk';
import { OptionsState } from '../../redux/options/reducers';
import { loadOptionsBasedStationThunk } from '../../redux/options/thunk';
import { createFactureRemboursementThunk, createRContraventionThunk, createReservationThunk, reservationEtats, updateReservation , updateReservationExterne} from '../../redux/reservations/thunk';
import { toISOLocal } from '../../utils/date';
import CenterTimeline from '../other/Activity/CenterTimeline';

interface Props {
  show: boolean;
  handleClose: () => void;
  vehicle?: Vehicle | null;
  reservation?: Reservation;
}

interface IFormValue {
  libele: string;
  numeroAvis: number;
  dateAvis: string;
  montant: number;
  montantFrais: number;
  
}

export default function RemboursementDialog({
  handleClose,
  show,
  vehicle = null,
  reservation
}: Props) {
  const dispatch = useAppDispatch();
  const { vehicleTypes } = useAppSelector(({ vehicleTypes }) => vehicleTypes) as VehicleTypestate;
  const { isLoading, availableVehiclesPerStation, listGearBoxes, listImmatriculations } = useAppSelector(({ vehicles }) => vehicles) as VehicleState;
  const { stations } = useAppSelector(({ stations }) => stations as StationState)
  const { optionsBasedStation } = useAppSelector(({ Options }) => Options as OptionsState)

  const [reservationNetToPay, setReservationNetToPay] = useState(0)
  const [reservationTotalToBePaid, setReservationTotalToBePaid] = useState(0)
  const [reservationAmountToBePaid, setReservationAmountToBePaid] = useState(0)

  const reservationId = reservation?._id;

  // const schemaResolver = yupResolver(
  //   yup.object().shape({
  //     libele: yup.string().required("Veuillez saisir un libellé"),
  //     numeroAvis: yup.string(),
  //     dateAvis: yup.string(),
  //     montant: yup.number().required("Veuillez saisir un montant"),
  //     montantFrais: yup.number().required("Veuillez saisir un montant de frais"),
  //   })
  // );

  const schemaResolver = yupResolver(
    yup.object().shape({
      montantRemboursement: yup.number().required("Veuillez saisir un montant de remboursement"),
    })
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    getValues,
  } = useForm<IFormValue>({
    resolver: schemaResolver,
    defaultValues: {},
  });

  const onSubmit = useCallback(
    (data) => {
      console.log("Form submitted with data:", data);
      const contraventionData = { ...data, reservationId };
      data.reservationId = reservationId;
      dispatch(createFactureRemboursementThunk(contraventionData));
      handleClose(); // Ferme le modal après la soumission du formulaire
     
    },
    [dispatch, reservationId]
  );

  const handleRembourserClick = () => {
    const formData = getValues(); // Obtenez les données du formulaire
    const contraventionData = { ...formData, reservationId };
    dispatch(createFactureRemboursementThunk(contraventionData));
    handleClose();
  };

  useEffect(() => {
    reset({});
  }, [reset, reservationId]);

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h3">
        {reservation?.status == "CANCELED" ? "Effectuer un remboursement" : "Effectuer un remboursement"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <form onSubmit={handleSubmit(onSubmit as any ) } className="authentication-form" noValidate>
          <Col>
            {/* <h4 className="text-md text-primary">Détails de la reservation</h4> */}
            <Row>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="number"
                  name="montantRemboursement"
                  label={'Montant du remboursement'}
                  containerClass={'mb-3'}
                  required = {true}
                />
              </Col>
            </Row>
          </Col>

          <div className="mb-3 text-center d-flex flex-row-reverse ms-auto gap-2">
            <Button type="submit" variant='primary'
              disabled={isLoading || isSubmitting}
            >
             Rembourser
            </Button>
            <Button variant="light" onClick={handleClose}>
              Annuler
            </Button>{' '}
          </div>
        </form>
        
        {/* <div className="mb-3 text-center d-flex flex-column gap-2">
          <h3>Le client sera crédité de {reservation?.amount} €. 
              Cliquer sur "<u><b>Rembouser</b></u>" pour effectuer le remboursement de ce montant.
            </h3>
          </div>
          <div className="mb-3 text-center d-flex flex-column gap-2">
          <Button variant="primary" onClick={handleRembourserClick}>
            Rembourser
          </Button>
          </div> 
      */}

      </Modal.Body>
    </Modal>
  )
}