import { VehicleInstance } from "../../types";
import { VehicleActionType, VEHICLE_INSTANCE_ACTIONS } from "./reducers";

/**
 * Load Vehicle action builder.
 * @param Vehicle
 * @returns
 */
export const setVehicleInstances = (Vehicle: VehicleInstance[]): VehicleActionType => ({
  type: VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_LIST,
  payload: Vehicle,
});

/**
 * Error action builder.
 * @param Vehicle
 * @returns
 */
 export const setVehicleInstanceError = (error: any): VehicleActionType => ({
  type: VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_ERROR,
  payload: error,
});

/**
 * Vehicle loading action builder.
 * @param Vehicle
 * @returns
 */
 export const setVehicleInstanceLoading = (isLoading: boolean): VehicleActionType => ({
  type: VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_LOADING,
  payload: isLoading,
});

/***
 * Set MCreate modal state
 */
export const setCreateVehicleInsanceModal = (show: boolean) : VehicleActionType => ({
  type: VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_CREATE_MODAL,
  payload: show,
})

/***
 * Set MCreate modal state
 */
export const setCalendarVehicleInsanceModal = (show: boolean) : VehicleActionType => ({
  type: VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_CALENDAR_MODAL,
  payload: show,
})

/**
 * set update Vehicle modal state.
 * @param show 
 * @returns 
 */
export const setUpdateVehicleInsanceModal = (show: boolean) : VehicleActionType => ({
  type: VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_UPDATE_MODAL,
  payload: show,
})

/**
 * List Vehicles action builder.
 * @param Vehicle
 * @returns
 */
export const listVehicleInstances = (Vehicle: VehicleInstance[]): VehicleActionType => ({
  type: VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_LIST_ALL,
  payload: Vehicle,
});

export const setDeleteLoading = (
  loading: boolean
): VehicleActionType => ({
  type: VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_DELETE_LOADING,
  payload: loading,
});