import { Etat, Planning, Reservation, ReservationHistory, ReservationRequest } from "../../types";
import {
  RESERVATIONS_ACTIONS,
  ReservationActionType,
  ReservationStripeStatus,
} from "./reducers";

/**
 * Load Station action builder.
 * @param Station
 * @returns
 */
export const setReservations = (
  type: Reservation[]
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_LIST,
  payload: type,
});

export const setReservationsRequestList = (payload: ReservationRequest[]): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATION_REQUEST_LIST,
  payload
})
export const setFacturesDownloadList = (payload: ReservationRequest[]): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.FACTURES_DOWNLOAD_LIST,
  payload
})

export const setPlanningList = (payload: ReservationRequest[]): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.FACTURES_DOWNLOAD_LIST,
  payload
})

export const setPlanning = (payload: Planning[]): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.PLANNING,
  payload
})


export const setReservationHistoryList = (payload: ReservationHistory[]): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATION_HISTORY_LIST,
  payload
})

export const setReservation = (payload: Reservation): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATION_FIND_ONE,
  payload
})

/**
 * Error action builder.
 * @param Station
 * @returns
 */
export const setReservationError = (error: any): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_ERROR,
  payload: error,
});

/**
 * Station loading action builder.
 * @param Station
 * @returns
 */
export const setReservationLoading = (
  isLoading: boolean
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_LOADING,
  payload: isLoading,
});


/***
 * Set MCreate modal state
 */
export const setValidateReservationModal = (
  show: boolean
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_VALIDATE_MODAL,
  payload: show,
});

/**
 * set update Station modal state.
 * @param show
 * @returns
 */
export const setCancelReservationModal = (
  show: boolean
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_CANCEL_MODAL,
  payload: show,
});

export const setReservationValidateStatus = (
  status: ReservationStripeStatus
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_VALIDATE_STATUS,
  payload: status,
});

export const setReservationCancelStatus = (
  status: ReservationStripeStatus
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_CANCEL_STATUS,
  payload: status,
});

export const setReservatioValidateLoading = (
  loading: boolean
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_VALIDATE_LOADING,
  payload: loading,
});

export const setReservatioCancelLoading = (
  loading: boolean
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_CANCEL_LOADING,
  payload: loading,
});

export const setReservationUpdateLoading = (
  loading: boolean
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATIONS_UPDATE_LOADING,
  payload: loading,
});


export const setOpenReservationEditionModal = (
  payload: boolean
  ): ReservationActionType => ({
    type: RESERVATIONS_ACTIONS.RESERVATION_OPEN_CLOSE_NEW_EDIT_MODAL,
    payload
  })

  export const setOpenContraventionEditionModal = (
    payload: boolean
    ): ReservationActionType => ({
      type: RESERVATIONS_ACTIONS.RESERVATION_CONTRAVENTION_OPEN_CLOSE_NEW_EDIT_MODAL,
      payload
    })

    export const setOpenRemboursementEditionModal = (
      payload: boolean
      ): ReservationActionType => ({
        type: RESERVATIONS_ACTIONS.RESERVATION_REMBOURSEMENT_OPEN_CLOSE_NEW_EDIT_MODAL,
        payload
    })

    
   


/**
 * Load Station action builder.
 * @param Etats
 * @returns
 */
export const setEtas = (
  type: Etat[]
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATION_ETATS,
  payload: type,
});

export const setDeleteLoading = (
  loading: boolean
): ReservationActionType => ({
  type: RESERVATIONS_ACTIONS.RESERVATION_DELETED_LOADING,
  payload: loading,
});
