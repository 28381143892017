import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Reservation, Station, Vehicle, VehicleInstance } from "../../types";
import ReservationCard from "./ReservationCard";
import DemenagerVehicleInstance from "./DemenagerVehicleInstance";

interface Props {
  show: boolean;
  handleClose: () => void;
  reservation: Reservation;
  // vehicle?: VehicleInstance | null;
}
//interface Props {
//   show: boolean;
//   handleClose: () => void;
//   vehicle?: VehicleInstance | null;
// }

export default function DetailsDialog({
  handleClose,
  show,
  reservation,
  // vehicle = null,
}: Props) {

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h4"> Modifier la réservation
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <DemenagerVehicleInstance reservation={reservation} show={false} handleClose={function (): void { } } />
        <ReservationCard reservation={reservation} />
      </Modal.Body>
    </Modal>
  )
}
