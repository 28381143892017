import React, { useCallback, useState } from 'react';
import './ripple.css';
import { useDispatch } from 'react-redux';
import { setCancelReservationModal, setEtas, setValidateReservationModal, setOpenReservationEditionModal, setOpenContraventionEditionModal, setOpenRemboursementEditionModal } from '../../redux/reservations/actions';
import axios from 'axios';
import { Reservation, Revision } from '../../types';
import { useAppSelector } from '../../redux/store';
import { InterventionState } from '../../redux/interventions/reducers';
import { setCreateinterventionModal } from '../../redux/interventions/actions';
import { onClickItem } from './CustomTimeline';


interface Item {
  bgColor: string;
  color: string;
  selectedBgColor: string;
}

interface ItemContext {
  selected: boolean;
  dragging: boolean;
  resizing: boolean;
  useResizeHandle: boolean;
  dimensions: {
    height: number;
  };
  title: string;
}

interface GetResizeProps {
  left: any;
  right: any;
}

interface Props {
  item: Item;
  itemContext: ItemContext;
  getItemProps: () => any;
  getResizeProps: () => GetResizeProps;
}

const ItemRender: React.FC<Props> = ({ item, itemContext, getItemProps, getResizeProps }) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const backgroundColor = itemContext.selected
    ? itemContext.dragging
      ? 'red'
      : item.selectedBgColor
    : item.bgColor;
  const borderColor = itemContext.resizing ? 'red' : item.color;
//   const dispatch = useDispatch();
//   const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
//   const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
//   const [revision, setRevision] = useState<Revision | null>(null);
//   const { interventions, showCreateModal } = useAppSelector(({ interventions }) => interventions) as InterventionState;

//   const handleOpenCloseReservationEdition = useCallback((payload: boolean) =>
//     () => dispatch(setOpenReservationEditionModal(payload)), [dispatch])
  
//   async function getReservationDetails(reservationId:string) {
//     const resp = await axios.get(`/admin/reservations/reservation-detail?reservationId=${reservationId}`)
//     setReservation(resp.data.result[0])
    
//           return resp.data.result[0]
//   }

//   async function getRevisionDetails(reservationId:string) {
//     const resp = await axios.get(`/admin/revisions/revision-detail?reservationId=${reservationId}`)
//     setRevision(resp.data.result)
//     console.log('resp.data', resp.data)
//     console.log(resp.data)
    
//           return resp.data.result;
//   }

//   const handleDetailShow = useCallback(() => setShowDetailsDialog(true), [setShowDetailsDialog])
//   const handleDetailClose = useCallback(() => setShowDetailsDialog(false), [setShowDetailsDialog])
 
//   const handleShow = useCallback(() => {
//    dispatch(setCreateinterventionModal(true))
//  }, [dispatch]);

//   const onClickItem = (item: any) => {
//     const monItem = item;
//     console.log('monItem');
//     console.log(monItem);
//     console.log('itemId');
//     // console.log(itemId);

//     if(monItem[0].itemProps.style.background === 'rgb(26, 111, 179)'){
//       getReservationDetails(monItem[0].id)
//       // handleDetailShow();
//       handleOpenCloseReservationEdition(true)()
//     }
//     if(monItem[0].itemProps.style.background === '#f55139'){
//       getReservationDetails(monItem[0].id)
//       handleDetailShow();
//     }
//     if(monItem[0].itemProps.style.background === 'yellow'){
//       getRevisionDetails(monItem[0].id)
//       // handleDetailShow();
      
//     console.log('revision');
//     console.log(revision);
//       handleShow();
//     }
    
//     // alert(monItem[0].itemProps.style.background);
//   };
  return (
    <div
        {...getItemProps({
            style: {
                backgroundColor,
                color: item.color,
                borderColor,
                border: itemContext.selected ? 'dashed 1px rgba(0,0,0,0.3)' : 'none',
                borderRadius: 4,
                boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.2),
                                     0 2px 2px 0 rgba(0, 0, 0, 0.14),
                                     0 3px 1px -2px rgba(0, 0, 0, 0.12)`
            },
            onMouseDown: () => {
                // console.log('on item click', item);
                onClickItem(item); // Remove the unnecessary argument
            }
        })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

      <div
        className="ripple"
        style={{
          height: itemContext.dimensions.height,
          overflow: 'hidden',
          paddingLeft: 3,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: '1rem',
          marginLeft: '1rem'
        }}
      >
        {itemContext.title}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    </div>
  );
};

export default ItemRender;
