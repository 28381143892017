import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';
import { setOpenReservationEditionModal } from '../../redux/reservations/actions';
import { ReservationsState } from '../../redux/reservations/reducers';
import { loadReservationsRequestsThunk } from '../../redux/reservations/thunk';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Reservation } from '../../types';
import EditFormDialog from './EditFormDialog';

const sizePerPageList = [
  {
    text: '5',
    value: 5,
  },
  {
    text: '10',
    value: 10,
  },
  {
    text: '25',
    value: 25,
  }
];

const ReservationRequest = () => {
  const dispatch = useAppDispatch();
  const { reservationsRequests, showNewAndEditReservationModal } = useAppSelector(({ Reservations }) => Reservations) as ReservationsState;
  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);

  const handleOpenCloseReservationEdition = useCallback((payload: boolean) =>
    () => dispatch(setOpenReservationEditionModal(payload)), [dispatch])

  useEffect(() => {
    if (reservationsRequests.length === 0) {
      dispatch(loadReservationsRequestsThunk({}));
    }
  }, [dispatch, reservationsRequests.length]);


  const columns = useMemo(() => [
    {
      Header: "Email client",
      accessor: 'clientEmail',
      sort: true,
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <span className="">
            { row?.original?.clientEmail }
          </span>
        )
      },
    },
    {
      Header: "Numero reservation",
      accessor: 'reservationExtern',
      sort: true,
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <span className="text-uppercase fw-bold">
            {row?.original?.reservationExtern}
          </span>
        )
      },
    },
    {
      id: "Actions",
      accessor: '',
      Header: "Action",
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <div className="d-flex align-items-center gap-2">
            <button
              className="btn btn-success rounded-3 d-flex gap-2 fw-bold text-uppercase"
              onClick={() => {
                setReservation({ ...row.original, clientEmail: row?.original?.clientEmail, reservationExtern: row?.original?.reservationExtern });
                handleOpenCloseReservationEdition(true)()
              }}
            >
              <span className="mr-2">
                <i className="bi bi-pencil-fill"></i>
              </span>
            </button>
          </div>
        );
      },
      disableGlobalFilter: true,
    },
  ], [handleOpenCloseReservationEdition]);

  return (
    <>
      <PageTitle
        breadCrumbItems={[]}
        title={"Demandes de reservation"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              {/* <h4 className="header-title"></h4> */}
              <Table
                columns={columns}
                data={reservationsRequests}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Create modal */}

      <EditFormDialog
        handleClose={handleOpenCloseReservationEdition(false)}
        reservation={reservation}
        show={showNewAndEditReservationModal}
       />
    </>
  );
};

export default ReservationRequest;
