import { Revision } from "../../types";

export enum INTERVENTIONS_ACTIONS {
  INTERVENTIONS_LIST = "INTERVENTIONS_LIST",
  INTERVENTIONS_ERROR = "INTERVENTIONS_ERROR",
  INTERVENTIONS_OPTIONS_MODAL = "INTERVENTIONS_OPTIONS_MODAL",
  INTERVENTIONS_UPDATE_MODAL = "INTERVENTIONS_UPDATE_MODAL",
  INTERVENTIONS_CREATE_MODAL = "INTERVENTIONS_CREATE_MODAL",
  INTERVENTIONS_LOADING = "INTERVENTIONS_LOADING",
  INTERVENTION_CANCEL_LOADING = "INTERVENTION_CANCEL_LOADING"
}

export interface InterventionState {
  interventions: Revision[];
  showCreateModal: boolean;
  showUpdateModal: boolean;
  isLoading: boolean;
  isCancelLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: InterventionState = {
  interventions: [],
  showCreateModal: false,
  showUpdateModal: false,
  isLoading: false,
  isCancelLoading: false,
  error: null,
};

export interface InterventionActionType {
  type: keyof typeof INTERVENTIONS_ACTIONS;
  payload: any;
}

const StationReducer = (
  state: InterventionState = initialState,
  action: InterventionActionType
): InterventionState => {
  switch (action.type) {
    case INTERVENTIONS_ACTIONS.INTERVENTIONS_LIST:
      return {
        ...state,
        interventions: action.payload,
        isLoading: false,
      };

    case INTERVENTIONS_ACTIONS.INTERVENTIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case INTERVENTIONS_ACTIONS.INTERVENTIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case INTERVENTIONS_ACTIONS.INTERVENTIONS_CREATE_MODAL:
      return {
        ...state,
        showCreateModal: action.payload,
      };

    case INTERVENTIONS_ACTIONS.INTERVENTIONS_UPDATE_MODAL:
      return {
        ...state,
        showUpdateModal: action.payload,
      };

    case INTERVENTIONS_ACTIONS.INTERVENTION_CANCEL_LOADING:
      return {
        ...state,
        isCancelLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default StationReducer;
