import { IOption } from "../../types";

export enum OPTION_ACTIONS {
  OPTION_LIST = "OPTION_LIST",
  OPTION_ERROR = "OPTION_ERROR",
  OPTION_UPDATE_MODAL = "OPTION_UPDATE_MODAL",
  OPTION_CREATE_MODAL = "OPTION_CREATE_MODAL",
  OPTION_LOADING = "OPTION_LOADING",
  OPTION_DELETED_LOADING = "OPTION_DELETED_LOADING",
  OPTION_LIST_STATION_BASED = "OPTION_LIST_STATION_BASED",
}

export interface OptionsState {
  options: IOption[];
  showCreateModal: boolean;
  showUpdateModal: boolean;
  isLoading: boolean;
  isDeletedLoading: boolean;
  optionsBasedStation: IOption[],
  error: { [x: string]: string } | string | null;
}

const initialState: OptionsState = {
  options: [],
  showCreateModal: false,
  showUpdateModal: false,
  isLoading: false,
  isDeletedLoading: false,
  optionsBasedStation: [],
  error: null,
};

export interface OptionActionType {
  type: keyof typeof OPTION_ACTIONS;
  payload: any;
}

const OptionReducer = (
  state: OptionsState = initialState,
  action: OptionActionType
): OptionsState => {
  switch (action.type) {
    case OPTION_ACTIONS.OPTION_LIST:
      return {
        ...state,
        options: action.payload,
        isLoading: false,
      };

    case OPTION_ACTIONS.OPTION_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case OPTION_ACTIONS.OPTION_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case OPTION_ACTIONS.OPTION_CREATE_MODAL:
      return {
        ...state,
        showCreateModal: action.payload,
      };

    case OPTION_ACTIONS.OPTION_UPDATE_MODAL:
      return {
        ...state,
        showUpdateModal: action.payload,
      };

    case OPTION_ACTIONS.OPTION_DELETED_LOADING:
      return {
        ...state,
        isDeletedLoading: action.payload,
      };
      case OPTION_ACTIONS.OPTION_LIST_STATION_BASED:
        return {
          ...state,
          optionsBasedStation: action.payload
        }
  

    default:
      return {
        ...state,
      };
  }
};

export default OptionReducer;
