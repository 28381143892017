import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from "react-calendar-timeline";
import generateFakeData from "./generate-fake-data";
import { ThreeDots } from "react-loader-spinner";
import { loadPlanningThunk, makeFatureByIdThunk } from '../../redux/reservations/thunk';
import { useAppSelector } from "../../redux/store";
import { ReservationsState } from "../../redux/reservations/reducers";
import { useDispatch } from 'react-redux';
import { Card, Row } from "react-bootstrap";
import { StationState } from "../../redux/stations/reducers";
import { loadVehicleThunk } from "../../redux/vehicle/thunk";
import { loadStationsThunk } from "../../redux/stations/thunk";
import { api } from "../../helpers/api/apiCore";
import { Reservation, Revision, VehicleInstance } from "../../types";
import axios from "axios";
import DetailsDialog from "./DetailsDialog";
import { setCancelReservationModal, setEtas, setValidateReservationModal, setOpenReservationEditionModal, setOpenContraventionEditionModal, setOpenRemboursementEditionModal } from '../../redux/reservations/actions';
import CreateFormDialog from "./CreateFormDialog";
import CreateFormDialogIntervention from "./CreateFormDialogIntervention";

import { setCreateinterventionModal } from '../../redux/interventions/actions';
import { InterventionState } from "../../redux/interventions/reducers";
import FeatherIcons from 'feather-icons-react';
import { Component } from "react";
import  {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import './style.css';
import ItemRender from "./itemRender";
// import itemRender from "./itemRender";

// import SundaysMarker from "./SundaysMarker";



function CustomTimeline() {

  const dispatch = useDispatch();

  const { stations } = useAppSelector(({ stations }) => stations) as StationState;
  const [immatriculation, setImmatriculation] = useState<string | undefined>(undefined);

  const [stationIdFilter, setStationIdFilter] = useState<string | undefined>(undefined);
  const [vehicleFilter, setVehicleFilter] = useState<string | undefined>(undefined);
  const [vehicleInstancesBackup, setVehicleInstancesBackup] = useState<VehicleInstance[]>([]);
  const [displayedVehicleInstances, setDisplayedVehicleInstances] = useState<VehicleInstance[]>([]);

  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
  const [revision, setRevision] = useState<Revision | null>(null);
  const { interventions, showCreateModal } = useAppSelector(({ interventions }) => interventions) as InterventionState;


  const handleDetailShow = useCallback(() => setShowDetailsDialog(true), [setShowDetailsDialog])
 const handleDetailClose = useCallback(() => setShowDetailsDialog(false), [setShowDetailsDialog])

 const handleShow = useCallback(() => {
  dispatch(setCreateinterventionModal(true))
}, [dispatch]);

const handleClose = useCallback(() => { dispatch(setCreateinterventionModal(false)); setRevision(null); }, [dispatch, setRevision]);





  const loadVehicleInstances = async () => {
    const res = await api.get(`/admin/vehicles/instances`)
    if (res.status === 200) {
      console.log("res.data", res.data.result)
      setDisplayedVehicleInstances(res.data.result)
      setVehicleInstancesBackup(res.data.result)
    }
  }
  const { planning, showNewAndEditReservationModal } = useAppSelector(({ Reservations }) => Reservations) as ReservationsState;
  let donnees = {
    groups: [],
    items1: []
  };
  const [datas, setDatas] = useState(null);
  const [isCharged, setIsCharged] = useState(false);

  useEffect(() => {
    // if (planning.length === 0) {
      // if (planning === null || datas === null) {
        if (datas === null) {
        setLoading(true);
        dispatch(loadPlanningThunk({}));
        setDatas(planning);
        setIsCharged(true);
      } else {
        setLoading(false);
      }
      dispatch(loadVehicleThunk());
    dispatch(loadStationsThunk());
  }, [dispatch, planning]);
  // let donnees = {
  //   groups: [],
  //   items1: []
  // };
  let keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title"
  };
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState(null);
  const [items, setItems] = useState([]);
  // const [datas, setDatas] = useState(donnees);
console.log('planning', planning)

  // useEffect(() => {
  //   // dispatch(loadPlanningThunk({}));
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const data = await generateFakeData();
  //       await new Promise(resolve => setTimeout(resolve, 60000));
  //       donnees.groups = data.groups;
  //       donnees.items1 = data.items1;
  //       setDatas(data)
  //       setGroups(data);
  //       setItems(data.items1);
  //       setDatas(data)
  //       if(donnees.groups.length > 10 && donnees.items1.length > 100 ){
  //         setLoading(false);
  //       }else{
  //         console.log("rien")
  //         console.log(donnees.groups.length )
  //         console.log(donnees.items1.length)
  //       }

  //       console.log('items')
  //       console.log(datas)
  //       console.log('datas')
  //       console.log(donnees)
  //     } catch (error) {
  //       console.error("Une erreur s'est produite lors du chargement des données:", error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const defaultTimeStart = moment().startOf("day").add(0, "day").toDate();
  const defaultTimeEnd = moment().startOf("day").add(25, "day").toDate();
  // await new Promise(resolve => setTimeout(resolve, 360000));
  // const donnees0 = donnees;

  let state = {
    groups: datas ? datas.groups : [],
    items : datas ? datas.items : [],
    defaultTimeStart,
    defaultTimeEnd,
    draggedItem:  undefined
  };

  const handleOpenCloseReservationEdition = useCallback((payload: boolean) =>
    () => dispatch(setOpenReservationEditionModal(payload)), [dispatch])

  async function getReservationDetails(reservationId:string) {
    const resp = await axios.get(`/admin/reservations/reservation-detail?reservationId=${reservationId}`)
    setReservation(resp.data.result[0])

          return resp.data.result[0]
  }

  async function getRevisionDetails(reservationId:string) {
    const resp = await axios.get(`/admin/revisions/revision-detail?reservationId=${reservationId}`)
    setRevision(resp.data.result)
    console.log('resp.data', resp.data)
    console.log(resp.data)

          return resp.data.result;
  }
  const handleItemMove = (itemId: any, dragTime: number, newGroupOrder: any) => {
    let { items, groups } = state;

    const group = groups[newGroupOrder];

    state = {
      ...state,
      items: items.map(item =>
        item.id === itemId
          ? Object.assign({}, item, {
              start: dragTime,
              end: dragTime + (item.end - item.start),
              group: group.id
            })
          : item
      ),
      draggedItem: undefined
    };

    console.log("Moved", itemId, dragTime, newGroupOrder);
  };

  const handleItemHover = (itemId: any, dragTime: number, newGroupOrder: any) => {
    let { items, groups } = state;

    // const group = groups[newGroupOrder];

    // state = {
    //   ...state,
    //   items: items.map(item =>
    //     item.id === itemId
    //       ? Object.assign({}, item, {
    //           start: dragTime,
    //           end: dragTime + (item.end - item.start),
    //           group: group.id
    //         })
    //       : item
    //   ),
    //   draggedItem: undefined
    // };

    console.log("Moved");
  };


  const handleItemResize = (itemId: any, time: any, edge: string) => {
    const { items } = state;

    state = {
      ...state,
      items: items.map(item =>
        item.id === itemId
          ? Object.assign({}, item, {
              start: edge === "left" ? time : item.start,
              end: edge === "left" ? item.end : time
            })
          : item
      ),
      draggedItem: undefined
    };

    console.log("Resized", itemId, time, edge);
  };

  const groupTitles = planning?.groups?.map(group => group.title) || [];
  const firstParts = Array.from(new Set(groupTitles.map(title => title.split('-')[0])));
  const uniqueFirstParts = Array.from(new Set(firstParts));


  const onClickItem = (itemId: any) => {
    const monItem = planning.items.filter(item => {
      return item.id.toString().toLowerCase().includes(itemId.toLowerCase());
    });
    console.log('monItem');
    console.log(monItem);
    console.log('itemId');
    console.log(itemId);

    if(monItem[0].itemProps.style.background === 'rgb(26, 111, 179)'){
      getReservationDetails(monItem[0].id)
      // handleDetailShow();
      handleOpenCloseReservationEdition(true)()
    }
    if(monItem[0].itemProps.style.background === '#f55139'){
      getReservationDetails(monItem[0].id)
      handleDetailShow();
    }
    if(monItem[0].itemProps.style.background === 'yellow'){
      getRevisionDetails(monItem[0].id)
      // handleDetailShow();

    console.log('revision');
    console.log(revision);
      handleShow();
    }

    // alert(monItem[0].itemProps.style.background);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      filterVehicleInstances();
    }, 1000);

    return () => clearTimeout(timer);
  }, [stationIdFilter, immatriculation]);

  const filterVehicleInstances = () => {
    let result = vehicleInstancesBackup;
    if (result.length === 0) {
      return
    }
    console.log("result, ", result.length)
    console.log("filter, ", stationIdFilter, immatriculation)
    // if(stationIdFilter) {
    //   // @ts-ignore
    //   result = result.filter(v => v?.station._id === stationIdFilter);
    // }
    if(immatriculation) {
      result = result.filter(v => {
        // filter by immatriculation or fuelTypes
        // return v.immatriculation.toLowerCase().includes(immatriculation.toLowerCase()) || v.vehicle.fuelTypes.toLowerCase().includes(immatriculation.toLowerCase());
        datas.groups = planning.groups.filter(group => group.id.toString().toLowerCase().includes(immatriculation.toLowerCase));
        // return  datas;
      });
    }
    setDisplayedVehicleInstances(result);
  }
  
  if(stationIdFilter && !immatriculation && !vehicleFilter) {
     console.log('stationIdFilter')
     console.log(stationIdFilter)
      // filter by immatriculation or fuelTypes
      // return v.immatriculation.toLowerCase().includes(immatriculation.toLowerCase()) || v.vehicle.fuelTypes.toLowerCase().includes(immatriculation.toLowerCase());
      datas.groups = planning.groups.filter(group => {
        return group.rightTitle.toString().toLowerCase().includes(stationIdFilter.toLowerCase());
      });
      // return  datas;
  }

  if(vehicleFilter && !stationIdFilter  && !immatriculation) {
    console.log('vehicleFilterFilter')
    console.log(vehicleFilter)
     // filter by immatriculation or fuelTypes
     // return v.immatriculation.toLowerCase().includes(immatriculation.toLowerCase()) || v.vehicle.fuelTypes.toLowerCase().includes(immatriculation.toLowerCase());
     datas.groups = planning.groups.filter(group => {
      return group.title.toString().toLowerCase().includes(vehicleFilter.toLowerCase());
     });
     console.log('datas vehicle')
    console.log(datas)
     // return  datas;
 }

  if(immatriculation && !stationIdFilter && !vehicleFilter) {
    console.log('stationIdFilter Immat')
     console.log(stationIdFilter)
    // filter by immatriculation or fuelTypes
    // return v.immatriculation.toLowerCase().includes(immatriculation.toLowerCase()) || v.vehicle.fuelTypes.toLowerCase().includes(immatriculation.toLowerCase());
    datas.groups = planning.groups.filter(group => {
      return group.title.toString().toLowerCase().includes(immatriculation.toLowerCase());
    });
    console.log('datas')
    console.log(datas)
    // return  datas;
  }

  if(stationIdFilter && immatriculation && vehicleFilter) {
    console.log('stationIdFilter')
    console.log(stationIdFilter)
     // filter by immatriculation or fuelTypes
     // return v.immatriculation.toLowerCase().includes(immatriculation.toLowerCase()) || v.vehicle.fuelTypes.toLowerCase().includes(immatriculation.toLowerCase());
     datas.groups = planning.groups.filter(group => {
       return group.rightTitle.toString().toLowerCase().includes(stationIdFilter.toLowerCase())
              && group.title.toString().toLowerCase().includes(immatriculation.toLowerCase())
              && group.title.toString().toLowerCase().includes(vehicleFilter.toLowerCase());
     });
     // return  datas;
 }

 if(stationIdFilter && !immatriculation && vehicleFilter) {
  console.log('stationIdFilter')
  console.log(stationIdFilter)
   // filter by immatriculation or fuelTypes
   // return v.immatriculation.toLowerCase().includes(immatriculation.toLowerCase()) || v.vehicle.fuelTypes.toLowerCase().includes(immatriculation.toLowerCase());
   datas.groups = planning.groups.filter(group => {
     return group.rightTitle.toString().toLowerCase().includes(stationIdFilter.toLowerCase())
            && group.title.toString().toLowerCase().includes(vehicleFilter.toLowerCase());
   });
   // return  datas;
}

if(stationIdFilter && immatriculation && !vehicleFilter) {
  console.log('stationIdFilter')
  console.log(stationIdFilter)
   // filter by immatriculation or fuelTypes
   // return v.immatriculation.toLowerCase().includes(immatriculation.toLowerCase()) || v.vehicle.fuelTypes.toLowerCase().includes(immatriculation.toLowerCase());
   datas.groups = planning.groups.filter(group => {
     return group.rightTitle.toString().toLowerCase().includes(stationIdFilter.toLowerCase())
            && group.title.toString().toLowerCase().includes(immatriculation.toLowerCase());
   });
   // return  datas;
}
  
if(!stationIdFilter && immatriculation && vehicleFilter) {
  console.log('stationIdFilter')
  console.log(stationIdFilter)
   // filter by immatriculation or fuelTypes
   // return v.immatriculation.toLowerCase().includes(immatriculation.toLowerCase()) || v.vehicle.fuelTypes.toLowerCase().includes(immatriculation.toLowerCase());
   datas.groups = planning.groups.filter(group => {
     return group.title.toString().toLowerCase().includes(immatriculation.toLowerCase())
            && group.title.toString().toLowerCase().includes(vehicleFilter.toLowerCase());
   });
   // return  datas;
}


  if(!stationIdFilter && !immatriculation && !vehicleFilter && isCharged && datas && datas.groups && datas.groups.length > 0 && datas.items && datas.items.length > 0) {
    console.log('stationIdFilter')
     console.log(stationIdFilter)
     datas.groups = planning.groups;
    // setDatas(planning);
  }

  const locale = {
    header: {
      format: 'DD MMM YYYY',
      dayFormat: 'ddd D MMM',
      monthFormat: 'MMMM YYYY',
    },
    labels: {
      year: 'Année',
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour',
      hour: 'Heure',
      minute: 'Minute',
      second: 'Seconde',
    },
    // Ajoutez les noms des mois et des jours ici
    months: [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ],
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  };

  return (
    <>
      {loading ? (
        <ThreeDots
          visible={true}
          height={Math.round(window.innerHeight * 0.05)}
          width={Math.round(window.innerHeight * 0.05)}
          color="#f55139"
          ariaLabel="rings-loading"
          wrapperStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          wrapperClass=""
        />
      ) : (
        // <Timeline
        //   groups={donnees0.groups}
        //   items={donnees0.items1} // Utilisez directement data.items1 ici
        //   itemTouchSendsClick={false}
        //   stackItems
        //   itemHeightRatio={2.75}
        //   canMove={true}
        //   canResize={"both"}
        //   defaultTimeStart={defaultTimeStart}
        //   defaultTimeEnd={defaultTimeEnd}
        // />
        <>
        <Row className="mb-3">
        <div className="col-6 col-md-3">
          <select className="form-select" aria-label="Default select example" onChange={(v) => {
            setStationIdFilter(v.target.value === "x" ? undefined : v.target.value as string | undefined)
          }}>
            <option selected value="x">Toutes les stations</option>
            {
              stations.map((station) => (
                <option value={station._id}>{station.name}</option>
              ))
            }
          </select>
        </div>
        <div className="col-6 col-md-3">
          <select className="form-select" aria-label="Default select example" onChange={(v) => {
            setVehicleFilter(v.target.value === "x" ? undefined : v.target.value as string | undefined)
          }}>
            <option selected value="x">Tous les véhicules</option>
            {
              firstParts.map((station: string) => (
                <option value={station}>{station}</option>
              ))
            }
          </select>
        </div>
        <div className="col-6 col-md-3">
          <input className="form-control" placeholder="Rechercher un véhicule" onChange={(v) => {
            setImmatriculation(v.target.value ? v.target.value : undefined);
          }} />
        </div>
        </Row>
        <Row>
        <div className="d-flex flex-row gap-2 mb-3">
                    <div className="d-flex align-items-center gap-1">
                        <div style={{height: 15, width: 15, borderRadius: '100px', backgroundColor: '#FCDC12', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><FeatherIcons icon="circle" size={15} color="white" /></div>
                        <span>Indisponible </span>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                        <div style={{height: 15, width: 15, borderRadius: '100px', backgroundColor: '#F55139', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><FeatherIcons icon="circle" size={15} color="white" /></div>
                        <span>Réservation Easygo</span>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                        <div style={{height: 15, width: 15, borderRadius: '100px', backgroundColor: '#4822ef', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><FeatherIcons icon="circle" size={15} color="white" /></div>
                        <span>Réservation externe</span>
                    </div>
                </div>
                </Row>
                <>
                <Card> 
      <Card.Body>
        <Timeline
        groups={datas.groups}
        items={datas.items}
        keys={keys}
        sidebarContent={<div>Above The Left</div>}
        itemsSorted
        itemTouchSendsClick={true}
        stackItems
        itemHeightRatio={0.75}
        showCursorLine
        canMove={true}
        canResize={false}
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
        onItemMove={handleItemMove}
        onItemResize={handleItemResize}
        // onItemDrag={handleItemDrag}
        onItemClick={onClickItem}
        sidebarWidth= {300}
        // onItemHover = {handleItemHover}
        // rightSidebarWidth={150}
        culture={'fr'}
        stickyHeader={true}
        stickyFooter={true}
        // stickyOffset={100}
        locale={locale}
        itemRenderer={ItemRender}
        lineHeight={50}
        fullUpdate

      >
        <TimelineHeaders className="header-sticky">
          <SidebarHeader>
            {({ getRootProps }) => {
              return <div {...getRootProps()}></div>;
            }}
          </SidebarHeader>
          <DateHeader unit="primaryHeader"  locale = 'fr' labelFormat="MMMM" />
          <DateHeader labelFormat="ddd D" style={{ width: '20px !important' }} />
          {/* <DateHeader
      // unit="day"
      labelFormat="D/MM/YYYY"
      // style={{ height: 50 }}
      // data={{someData: 'example'}}
      // intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
      //   return <div {...getIntervalProps()}>
      //     {intervalContext.intervalText}
      //     {data.example}
      //   </div>
      // }}
    /> */}
        </TimelineHeaders>
        <TimelineMarkers>
            <TodayMarker>
              {({ styles, date }) => (
                <div
                  style={{
                    ...styles,
                    width: "0.5rem",
                    backgroundColor: "rgba(255,0,0,0.5)",
                  }}
                />
              )}
            </TodayMarker>
            {/* <SundaysMarker /> */}
          </TimelineMarkers>
      </Timeline>
      </Card.Body>
      </Card>
      </>

      </>
        // <>Fin</>
      )}
      {reservation ? (
        <>
          {/* <ValidateDialog
            handleClose={handleValidateClose}
            show={showValidateModal}
            reservation={reservation}
          />
          <CancelDialog
            handleClose={handleCancelClose}
            show={showCancelModal}
            reservation={reservation}
          /> */}
          <CreateFormDialog
        handleClose={handleOpenCloseReservationEdition(false)}
        reservation={reservation}
        show={showNewAndEditReservationModal}
       />
          <DetailsDialog
            handleClose={handleDetailClose}
            reservation={reservation}
            show={showDetailsDialog}
          />
        </>
      ) : (<></>)}
      <CreateFormDialogIntervention
        revision={revision}
        handleClose={handleClose}
        show={showCreateModal}
      />
    </>
  );
}

export const onClickItem = (planning: any, handleOpenCloseReservationEdition: any, handleDetailShow: any, handleShow: any, setReservation: any, setRevision: any) => (itemId: any) => {
  const monItem = planning.items.filter((item: any) => {
    return item.id.toString().toLowerCase().includes(itemId.toLowerCase());
  });

  async function getReservationDetails(reservationId:string) {
    const resp = await axios.get(`/admin/reservations/reservation-detail?reservationId=${reservationId}`)
    setReservation(resp.data.result[0])

          return resp.data.result[0]
  }

  async function getRevisionDetails(reservationId:string) {
    const resp = await axios.get(`/admin/revisions/revision-detail?reservationId=${reservationId}`)
    setRevision(resp.data.result)
    console.log('resp.data', resp.data)
    console.log(resp.data)

          return resp.data.result;
  }

  if (monItem[0].itemProps.style.background === 'rgb(26, 111, 179)') {
    getReservationDetails(monItem[0].id);
    handleOpenCloseReservationEdition(true)();
  }
  if (monItem[0].itemProps.style.background === '#f55139') {
    getReservationDetails(monItem[0].id);
    handleDetailShow();
  }
  if (monItem[0].itemProps.style.background === 'yellow') {
    getRevisionDetails(monItem[0].id);
    handleShow();
  }
};

export default CustomTimeline;
function dispatch(arg0: (dispatch: any, getState: any, api: import("../../helpers/api/apiCore").APICore) => Promise<void>) {
  throw new Error("Function not implemented.");
}

