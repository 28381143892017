import { Modal } from "react-bootstrap";
import { useAppDispatch } from "../../redux/store";
import { Etat, Reservation } from "../../types";
import ReservationCard from "./ReservationCard";

interface Props {
  show: boolean;
  handleClose: () => void;
  etat: Etat;
}

export default function DetailsDialog({
  handleClose,
  show,
  etat,
}: Props) {
  const dispatch = useAppDispatch();

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h4">
          Details de la reservation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{etat.description}</p>
        <div className="d-flex justify-content-between">
          {
            etat.photos?.map((photo) => {
              return(
                <a
                  href={photo}
                  target="_blank"
                  className="border border-primary mb-1 border-bottom"
                  style={{
                    width: "33.333%",
                    height: "250px",
                    overflow: 'hidden'
                  }}
                >
                  <img
                    src={photo}
                    alt="Permis de conduire"
                    style={{
                      objectFit: 'cover',
                      backgroundPosition: 'center',
                      backgroundSize: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </a>
              )
            })
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}
