import { APICore } from "../../helpers/api/apiCore";
import { VehicleInstance } from "../../types";
import {
  listVehicleInstances,
  setCreateVehicleInsanceModal,
  setDeleteLoading,
  setVehicleInstanceError,
  setVehicleInstanceLoading,
  setVehicleInstances
} from "./actions";

export interface VehicleData {
  id?: string;
  immatriculation: string;
  unLockCentralId: string;
  vehicleId: string;
  stationId: string;
  inServiceSince: Date;

  hasClimatisation: boolean;
  hasGps: boolean;
  hasUsb: boolean;
  hasBluetooth: boolean;
  hasAndroidAuto: boolean;
  hasAppleCarPlay: boolean;
}

/**
 * Load vehicles thunk.
 *
 * @param param0
 * @returns
 */
export const loadVehicleInstanceThunk = (filter: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setVehicleInstanceLoading(true));
      let params = {};

      if(filter.station) {
        params = {station: filter.station};
        //filter = {...filter, ...{vehicleInstanceId: id}}
      }

      const resp = await api.get(`/admin/vehicles/instances`, params);

      if (resp.data?.status === "OK") {
        if(filter.immatriculation) {
          const filterdVehicles = resp.data.result.filter((o: VehicleInstance) => o.immatriculation.includes(filter.immatriculation));
          dispatch(setVehicleInstances(filterdVehicles));
        } else {
          dispatch(setVehicleInstances(resp.data.result));
        }
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setVehicleInstanceError(err));
    }
  };
};

/**
 * Create vehicle thunk.
 *
 * @param param0
 * @returns
 */
export const createVehicleInstanceThunk = (data: VehicleData) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const resp = await api.create(
        `/admin/vehicles/${data.vehicleId}/instances`,
        {
          ...data,
        }
      );

      if (resp.data) {
        dispatch(loadVehicleInstanceThunk({}));
        dispatch(setCreateVehicleInsanceModal(false));
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setVehicleInstanceError(err));
    }
  };
};

/**
 * Create vehicle thunk.
 *
 * @param param0
 * @returns
 */
export const updateVehicleInstanceThunk = (data: VehicleData) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      console.log('id Vehicle')
      console.log(data)
      const resp = await api.update(
        `/admin/vehicles/${data.vehicleId!}/instances/${data.id!}`,
        data
      );
      console.log('data vehicle')
      console.log(data);

      if (resp?.data?.status === "OK") {
        dispatch(loadVehicleInstanceThunk({}));
        dispatch(setCreateVehicleInsanceModal(false));
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setVehicleInstanceError(err));
    }
  };
};

/**
 * Load vehicles thunk.
 *
 * @param param0
 * @returns
 */
export const getAvailableVehicleInstanceThunk = (station: any, startAt: string, endAt: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const resp = await api.get(`/admin/vehicles/station/instances?station=${station}&startAt=${startAt}&endAt=${endAt}`);

      if (resp.data?.status === "OK") {
        dispatch(listVehicleInstances(resp.data.result));
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      //dispatch(setVehicleInstanceError(err));
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const deleteVehicleInstanceThunk = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setDeleteLoading(true));
      const resp = await api.delete(`/admin/vehicles/instance/${id}`);

      if (resp.data?.status === "OK") {
        dispatch(setCreateVehicleInsanceModal(false));
        dispatch(loadVehicleInstanceThunk({}));
      }

      dispatch(setDeleteLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setDeleteLoading(false));
    }
  };
};
