import { Etat, Planning, Reservation } from "../../types";


export enum RESERVATIONS_ACTIONS {
  RESERVATIONS_LIST = "RESERVATIONS_LIST",
  RESERVATIONS_ERROR = "RESERVATIONS_ERROR",
  RESERVATIONS_CANCEL_MODAL = "RESERVATIONS_CANCEL_MODAL",
  RESERVATIONS_VALIDATE_MODAL = "RESERVATIONS_VALIDATE_MODAL",
  RESERVATIONS_VALIDATE_STATUS = "RESERVATIONS_VALIDATE_STATUS",
  RESERVATIONS_CANCEL_STATUS = "RESERVATIONS_CANCEL_STATUS",
  RESERVATIONS_LOADING = "RESERVATIONS_LOADING",
  RESERVATIONS_VALIDATE_LOADING = "RESERVATIONS_VALIDATE_LOADING",
  RESERVATIONS_CANCEL_LOADING = "RESERVATIONS_CANCEL_LOADING",
  RESERVATIONS_UPDATE_LOADING = "RESERVATIONS_UPDATE_LOADING",
  RESERVATIONS_UPDATE = "RESERVATIONS_UPDATE",
  RESERVATION_ETATS = "RESERVATION_ETATS",
  RESERVATION_DELETED_LOADING = "RESERVATION_DELETED_LOADING",
  RESERVATION_OPEN_CLOSE_NEW_EDIT_MODAL = "RESERVATION_OPEN_CLOSE_NEW_EDIT_MODAL",
  RESERVATION_CONTRAVENTION_OPEN_CLOSE_NEW_EDIT_MODAL = "RESERVATION_CONTRAVENTION_OPEN_CLOSE_NEW_EDIT_MODAL",
  RESERVATION_REMBOURSEMENT_OPEN_CLOSE_NEW_EDIT_MODAL = "RESERVATION_REMBOURSEMENT_OPEN_CLOSE_NEW_EDIT_MODAL",
  RESERVATION_REQUEST_LIST = "RESERVATION_REQUEST_LIST",
  RESERVATION_HISTORY_LIST = "RESERVATION_HISTORY_LIST",
  RESERVATION_FIND_ONE = "RESERVATION_FIND_ONE",
  FACTURES_DOWNLOAD_LIST = "FACTURES_DOWNLOAD_LIST",
  PLANNING = "PLANNING"
}

export type ReservationStripeStatus = {
  status: "SUCCESS" | "ERROR";
  msg: string;
} | null;

export interface ReservationsState {
  reservations: Reservation[];
  reservationsRequests: [];
  reservationsHistory: [];
  reservation: Reservation | null;
  facturesDownload: [];
  planning: Planning | null;
  showValidateModal: boolean;
  showCancelModal: boolean;
  showNewAndEditReservationModal: boolean;
  showNewAndEditContraventionModal: boolean;
  showNewAndEditRemboursementModal: boolean;
  reservationValidateStatus: ReservationStripeStatus;
  reservationCancelStatus: ReservationStripeStatus;
  isValidateLoading: boolean;
  isCancelLoading: boolean;
  isUpdateLoading: boolean;
  isLoading: boolean;
  isDeletedLoading: boolean,
  error: { [x: string]: string } | string | null;
  etats: Etat[];
}

const initialState: ReservationsState = {
  reservations: [],
  reservationsRequests: [],
  reservationsHistory: [],
  reservation: null,
  facturesDownload: [],
  planning: null,
  showValidateModal: false,
  reservationValidateStatus: null,
  reservationCancelStatus: null,
  showCancelModal: false,
  isLoading: false,
  isCancelLoading: false,
  isValidateLoading: false,
  isUpdateLoading: false,
  isDeletedLoading: false,
  showNewAndEditReservationModal: false,
  showNewAndEditContraventionModal: false,
  showNewAndEditRemboursementModal: false,
  error: null,
  etats: [],
};

export interface ReservationActionType {
  type: keyof typeof RESERVATIONS_ACTIONS;
  payload: any;
}

const reservationsReducer = (
  state: ReservationsState = initialState,
  action: ReservationActionType
): ReservationsState => {
  switch (action.type) {
    case RESERVATIONS_ACTIONS.RESERVATIONS_LIST:
      return {
        ...state,
        reservations: action.payload,
        isLoading: false,
      };

    case RESERVATIONS_ACTIONS.RESERVATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case RESERVATIONS_ACTIONS.RESERVATIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case RESERVATIONS_ACTIONS.RESERVATIONS_VALIDATE_MODAL:
      return {
        ...state,
        showValidateModal: action.payload,
      };

    case RESERVATIONS_ACTIONS.RESERVATIONS_CANCEL_MODAL:
      return {
        ...state,
        showCancelModal: action.payload,
      };

    case RESERVATIONS_ACTIONS.RESERVATIONS_VALIDATE_STATUS:
      return {
        ...state,
        reservationValidateStatus: action.payload,
      };

    case RESERVATIONS_ACTIONS.RESERVATIONS_CANCEL_STATUS:
      return {
        ...state,
        reservationCancelStatus: action.payload,
      };

    case RESERVATIONS_ACTIONS.RESERVATIONS_VALIDATE_LOADING:
      return {
        ...state,
        isValidateLoading: action.payload,
      };

    case RESERVATIONS_ACTIONS.RESERVATIONS_CANCEL_LOADING:
      return {
        ...state,
        isCancelLoading: action.payload,
      };


    case RESERVATIONS_ACTIONS.RESERVATIONS_UPDATE_LOADING:
      return {
        ...state,
        isUpdateLoading: action.payload,
      };

    case RESERVATIONS_ACTIONS.RESERVATION_ETATS:
      return {
        ...state,
        etats: action.payload,
      }

    case RESERVATIONS_ACTIONS.RESERVATION_DELETED_LOADING:
      return {
        ...state,
        isDeletedLoading: action.payload,
      };

      case RESERVATIONS_ACTIONS.RESERVATION_OPEN_CLOSE_NEW_EDIT_MODAL:
        return {
          ...state,
          showNewAndEditReservationModal: action.payload
        };
        
        case RESERVATIONS_ACTIONS.RESERVATION_CONTRAVENTION_OPEN_CLOSE_NEW_EDIT_MODAL:
        return {
          ...state,
          showNewAndEditContraventionModal: action.payload
        };

        case RESERVATIONS_ACTIONS.RESERVATION_REMBOURSEMENT_OPEN_CLOSE_NEW_EDIT_MODAL:
        return {
          ...state,
          showNewAndEditRemboursementModal: action.payload
        };

        
      case RESERVATIONS_ACTIONS.RESERVATION_REQUEST_LIST:
        return {
          ...state,
          reservationsRequests: action.payload,
        }

        case RESERVATIONS_ACTIONS.RESERVATION_HISTORY_LIST:
        return {
          ...state,
          reservationsHistory: action.payload,
        }
        
        case RESERVATIONS_ACTIONS.RESERVATION_FIND_ONE:
        return {
          ...state,
          reservation: action.payload,
        }
        
        case RESERVATIONS_ACTIONS.FACTURES_DOWNLOAD_LIST:
        return {
          ...state,
          facturesDownload: action.payload,
        }
  
        case RESERVATIONS_ACTIONS.PLANNING:
        return {
          ...state,
          planning: action.payload,
        }

    default:
      return {
        ...state,
      };
  }
};

export default reservationsReducer;
