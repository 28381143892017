export const formatDateLocal = (givenDate: string | Date) => {
  
  const date = new Date(givenDate);

  return date.toLocaleDateString("fr-FR", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric"
  });
};


export const formatDateTimeLocal = (givenDate: string | Date) => {
  const date = new Date(givenDate);

  return date.toLocaleString("fr-FR", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatTime = (date: any) => {
  return date.replace(/^([^\d]*\d{1,2}:\d{1,2}):\d{1,2}([^\d]*)$/, '$1$2');
}

export const toISOLocal = (d: any) => {
  var z  = (n: any) => ('0' + n).slice(-2);
  var zz = (n: any) => ('00' + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off > 0? '-' : '+';
  off = Math.abs(off);

  return d.getFullYear() + '-'
         + z(d.getMonth()+1) + '-' +
         z(d.getDate()) + 'T' +
         z(d.getHours()) + ':'  + 
         z(d.getMinutes()) + ':' +
         z(d.getSeconds()) + '.' +
         zz(d.getMilliseconds()) +
         sign + z(off/60|0) + ':' + z(off%60); 
}