import { APICore } from "../../helpers/api/apiCore";
import { IVeheculePrices } from "../../types";
import {
  setCreateVehicleModal,
  setDeleteLoading,
  setPricesVehicleModal,
  setVehicleError,
  setVehicleLoading,
  setVehicles,
  setVehiculeImmatriculationList,
  setAvailableVehiculesPerStation,
  setVehicleGearBoxes
} from "./actions";

export interface VehicleData {
  name: string;
  vehicleType: string;
  photo: File;
  station: string;
  dailyPrice: number;
  id: string;
  brand: string; //marque
  inServiceSince: Date; //Date de mise en circulation
  doorsCount: number; //nombre de portes
  seatsCount: number; //nombre de sieges
  kilometreage: number;
  gearbox: string; // boites de vitesse => "auto" | "manuel" | "semi-auto"
  fuelTypes: string;
}

/**
 * Load vehicles thunk.
 *
 * @param param0
 * @returns
 */
export const loadVehicleThunk = () => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setVehicleLoading(true));

      const resp = await api.get("/Vehicles");

      if (resp.data?.status === "OK") {
        dispatch(setVehicles(resp.data.result));
        dispatch(setVehicleLoading(false));
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }
      dispatch(setVehicleLoading(false));
      dispatch(setVehicleError(err));
    }
  };
};


export const loadVehicleAvailblePerStation = ({ station, from, to }: { station: string; from: string; to: string; }) => async (dispatch: any, getState: any, api: APICore) => {
  try {
    console.log("station")
    console.log(station)
    console.log("from")
    console.log(from)
    console.log("to")
    console.log(to)
    dispatch(setVehicleLoading(true));

    // const resp = await api.get(`/vehicles/instances-available?station=${station}`);

    
    let qs = `from=${from}&to=${to}&station=${station}`;
    

    // const resp = await api.get(`/vehicles/available?station=${station}&from=${from}&to=${to}`);

    const resp = await api.get(`/vehicles/available?${qs}`);

    console.log(resp.data)
    if (resp.data?.status === "OK") {
      console.log("azeto")
      console.log(resp.data)
      dispatch(setAvailableVehiculesPerStation(resp.data.result));
      dispatch(setVehicleLoading(false));
    }
  } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }
      dispatch(setVehicleLoading(false));
      dispatch(setVehicleError(err));
  }
}

export const loadGearBoxes = ({ name, brand, station }: { name: string; brand: string; station: string; }) => async (dispatch: any, getState: any, api: APICore) => {
  try {

    dispatch(setVehicleLoading(true));

    const resp = await api.get(`/vehicles/gearboxes?name=${name}&brand=${brand}&station=${station}`);

    if (resp.data?.status === "OK") {
      dispatch(setVehicleGearBoxes(resp.data.gearBoxes));
      dispatch(setVehicleLoading(false));
    }
  } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }
      dispatch(setVehicleLoading(false));
      dispatch(setVehicleError(err));
  }
}

export const loadVehicleImmatriculation = ({ name, brand, gearbox, station, from, to }: { name: string; brand: string; gearbox: string; station: string, from: string, to: string }) =>
  async (dispatch: any, getState: any, api: APICore) => {
    try {

      dispatch(setVehicleLoading(true));

      const resp = await api.get(`/vehicles/immatriculations?name=${name}&brand=${brand}&gearbox=${gearbox}&station=${station}&from=${from}&to=${to}`);

      if (resp.data?.status === "OK") {
        dispatch(setVehiculeImmatriculationList(resp.data.immatriculations));
        dispatch(setVehicleLoading(false));
      }
    } catch (error: any) {
        let err = error;
        if (error.response) {
          err = error.response.data.error;
        }
        dispatch(setVehicleLoading(false));
        dispatch(setVehicleError(err));
    }
}


/**
 * Create vehicle thunk.
 *
 * @param param0
 * @returns
 */
export const createVehicleThunk = ({
  name,
  photo,
  vehicleType,
  dailyPrice,
  brand,
  inServiceSince,
  doorsCount,
  seatsCount,
  kilometreage,
  gearbox,
  fuelTypes,
}: VehicleData) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const formData = new FormData();

      formData.append("name", name);
      formData.append("vehicleTypeId", vehicleType);
      formData.append("dailyPrice", dailyPrice.toString());
      formData.append("photoUrl", photo);
      formData.append("brand", `${brand}`);
      formData.append("inServiceSince", `${inServiceSince}`);
      formData.append("doorsCount", `${doorsCount}`);
      formData.append("seatsCount", `${seatsCount}`);
      formData.append("kilometreage", `${kilometreage}`);
      formData.append("gearbox", `${gearbox}`);
      formData.append("fuelTypes", `${fuelTypes}`);

      const resp = await api.create("/admin/vehicles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (resp.data) {
        dispatch(loadVehicleThunk());
        dispatch(setCreateVehicleModal(false));
      }
    } catch (error: any) {
      console.log({ error });

      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }
      dispatch(setVehicleLoading(false));
      dispatch(setVehicleError(err));
    }
  };
};

/**
 * Create vehicle thunk.
 *
 * @param param0
 * @returns
 */
export const updateVehicleThunk = ({
  name,
  photo,
  vehicleType,
  dailyPrice,
  brand,
  inServiceSince,
  doorsCount,
  seatsCount,
  kilometreage,
  gearbox,
  fuelTypes,
  id,
}: {
  name: string;
  vehicleType: string;
  photo?: File | null;
  station: string;
  dailyPrice: number;
  id: string;
  brand: string; //marque
  inServiceSince: Date; //Date de mise en circulation
  doorsCount: number; //nombre de portes
  seatsCount: number; //nombre de sieges
  kilometreage: number;
  gearbox: string; // boites de vitesse => "auto" | "manuel" | "semi-auto"
  fuelTypes: string;
}) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const formData = new FormData();

      formData.append("name", name);
      formData.append("vehicleTypeId", vehicleType);
      formData.append("dailyPrice", `${dailyPrice}`);
      formData.append("brand", `${brand}`);
      formData.append("inServiceSince", `${inServiceSince}`);
      formData.append("doorsCount", `${doorsCount}`);
      formData.append("seatsCount", `${seatsCount}`);
      formData.append("kilometreage", `${kilometreage}`);
      formData.append("gearbox", `${gearbox}`);
      formData.append("fuelTypes", `${fuelTypes}`);

      if (photo) {
        formData.append("photoUrl", photo);
      }

      const resp = await api.update(`/admin/vehicles/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (resp?.data?.status === "OK") {
        dispatch(loadVehicleThunk());
        dispatch(setCreateVehicleModal(false));
      }
    } catch (error: any) {
      console.log({ error });

      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }
      dispatch(setVehicleLoading(false));
      dispatch(setVehicleError(err));
    }
  };
};


/**
 * Create vehicle thunk.
 *
 * @param param0
 * @returns
 */
export const updatePricesThunk = (prices: IVeheculePrices, id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const formData = new FormData();

      console.log(prices);

      formData.append("JanuaryPrice", `${prices.JanuaryPrice}`);
      formData.append("FebruaryPrice", `${prices.FebruaryPrice}`);
      formData.append("MarchPrice", `${prices.MarchPrice}`);
      formData.append("AprilPrice", `${prices.AprilPrice}`);
      formData.append("MayPrice", `${prices.MayPrice}`);
      formData.append("JunePrice", `${prices.JunePrice}`);
      formData.append("JulyPrice", `${prices.JulyPrice}`);
      formData.append("AugustPrice", `${prices.AugustPrice}`);
      formData.append("SeptemberPrice", `${prices.SeptemberPrice}`);
      formData.append("OctoberPrice", `${prices.OctoberPrice}`);
      formData.append("NovemberPrice", `${prices.NovemberPrice}`);
      formData.append("DecemberPrice", `${prices.DecemberPrice}`);

      formData.append("firstPercent", `${prices.firstPercent}`);
      formData.append("secondPercent", `${prices.secondPercent}`);
      formData.append("thirdPercent", `${prices.thirdPercent}`);

      const resp = await api.update(`/admin/vehicles/${id}/prices`, prices, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (resp?.data?.status === "OK") {
        dispatch(loadVehicleThunk());
        dispatch(setPricesVehicleModal(false));
      }
    } catch (error: any) {
      console.log({ error });

      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }
      dispatch(setVehicleLoading(false));
      dispatch(setVehicleError(err));
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const deleteVehicleThunk = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setDeleteLoading(true));
      const resp = await api.delete(`/admin/vehicles/${id}`);

      if (resp.data?.status === "OK") {
        dispatch(setCreateVehicleModal(false));
        dispatch(loadVehicleThunk());
      }

      dispatch(setDeleteLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setDeleteLoading(false));
    }
  };
};
