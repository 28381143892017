// Importez les bibliothèques et composants nécessaires
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FormInput } from '../../components';
import { createRContraventionThunk } from '../../redux/reservations/thunk';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Contravention } from '../../types';
import { useParams } from 'react-router-dom';

// Définissez votre schéma de validation Yup
const schemaResolver = yupResolver(
  yup.object().shape({
    libele: yup.string().required("Veuillez saisir un libellé"),
    // numeroAvis: yup.string().required("Veuillez saisir un numéro d'avis"),
    // dateAvis: yup.string().required("Veuillez saisir une date d'avis"),
    
    numeroAvis: yup.string(),
    dateAvis: yup.string(),
    montant: yup.number().required("Veuillez saisir un montant"),
    montantFrais: yup.number().required("Veuillez saisir un montant de frais"),
  
  })
);

// Définissez le composant de la page
export default function AddContraventionPage() {
    const { reservationId } = useParams<{ reservationId: string }>();
  const dispatch = useAppDispatch();
//   const { isLoading } = useAppSelector(({ contraventions }) => contraventions);

  // Utilisez useCallback pour éviter que la fonction onSubmit ne change à chaque rendu
//   const onSubmit = useCallback((data: Contravention) => {
//     (data: Contravention) => {
//         // Inclure reservationId dans l'objet data
//         const contraventionData = { ...data, reservationId };
//         // Appeler l'action avec les données mises à jour
//         dispatch(createRContraventionThunk(contraventionData));
//       },
//     // dispatch(createRContraventionThunk(data));
//   }, [dispatch]);

const onSubmit = useCallback(
    (data: Contravention) => {
        console.log('yu');
      const contraventionData = { ...data, reservationId };
      dispatch(createRContraventionThunk(contraventionData));
    },
    [dispatch, reservationId]  // Ajoutez reservationId comme dépendance
  );
  

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Contravention>({
    resolver: schemaResolver,
    defaultValues: {},
  });

  // Utilisez useEffect pour réinitialiser le formulaire lorsque la page est montée
//   useEffect(() => {
//     reset({});
//   }, [reset]);

  useEffect(() => {
    reset({});
  }, [reset, reservationId]);
  // Retournez la structure JSX de votre page
  return (
    <div className="container">
      <Row className="justify-content-center mt-5">
        <Col md={8}>
          <form onSubmit={handleSubmit(onSubmit)} className="authentication-form" noValidate>
          <Row>
            <Col className="col-12">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="text"
                name="libele"
                required
                placeholder="Libellé de la contravention"
                label={'Libellé de la contravention'}
                containerClass={'mb-3'}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="text"
                name="numeroAvis"
                placeholder="Numéro d'avis"
                label={'Numéro d\'avis'}
                containerClass={'mb-3'}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="date"
                name="dateAvis"
                placeholder="Date d'avis"
                label={'Date d\'avis'}
                containerClass={'mb-3'}
              />
            </Col>
            <Col className="col-12">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="number"
                name="montant"
                min={0}
                required
                placeholder="Montant de la contravention"
                label={'Montant de la contravention'}
                containerClass={'mb-3'}
              />
            </Col>
            <Col className="col-12">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="number"
                name="montantFrais"
                min={0}
                required
                placeholder="Frais de traitement d'une contravention"
                label={'Frais de traitement d\'une contravention'}
                containerClass={'mb-3'}
              />
            </Col>
            {/* <Col className="col-12">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="file"
                name="nomFichier"
                label={'Fichier de la contravention'}
                containerClass={'mb-3'}
              />
            </Col> */}
          </Row>

            <div className="mb-3 text-center d-flex flex-column gap-2">
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                Ajouter
              </Button>
              <Button variant="light">
                Annuler
              </Button>{' '}
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
}
