import { KmForfait, KmPackConfig } from "../../types";

export enum KM_FORFAITS_ACTIONS {
  KM_FORFAITS_LIST = "KM_FORFAITS_LIST",
  KM_FORFAITS_PRICE = "KM_FORFAITS_PRICE",
  KM_FORFAITS_PRICE_ERROR = "KM_FORFAITS_PRICE_ERROR",
  KM_FORFAITS_ERROR = "KM_FORFAITS_ERROR",
  KM_FORFAIT_MODAL = "KM_FORFAIT_MODAL",
  KM_FORFAIT_PRICES_MODAL = "KM_FORFAIT_PRICES_MODAL",
  KM_FORFAITS_LOADING = "KM_FORFAITS_LOADING",
}

export interface KmForfaitstate {
  kmForfaits: KmForfait[];
  kmForfaitPrice: KmPackConfig | null;
  showModal: boolean;
  showKmPricesModal: boolean;
  isLoading: boolean;
  priceError: { [x: string]: string } | string | null;
  error: { [x: string]: string } | string | null;
}

const initialState: KmForfaitstate = {
  kmForfaits: [],
  kmForfaitPrice: null,
  showModal: false,
  showKmPricesModal: false,
  isLoading: false,
  error: null,
  priceError: null,
};

export interface KmForfaitActionType {
  type: keyof typeof KM_FORFAITS_ACTIONS;
  payload: any;
}

const vehicleTypeReducer = (
  state: KmForfaitstate = initialState,
  action: KmForfaitActionType
): KmForfaitstate => {
  switch (action.type) {
    case KM_FORFAITS_ACTIONS.KM_FORFAITS_LIST:
      return {
        ...state,
        kmForfaits: action.payload,
        isLoading: false,
      };

    case KM_FORFAITS_ACTIONS.KM_FORFAITS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case KM_FORFAITS_ACTIONS.KM_FORFAITS_PRICE_ERROR:
      return {
        ...state,
        priceError: action.payload,
        isLoading: false,
      };

    case KM_FORFAITS_ACTIONS.KM_FORFAITS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case KM_FORFAITS_ACTIONS.KM_FORFAIT_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };

    case KM_FORFAITS_ACTIONS.KM_FORFAITS_PRICE:
      return {
        ...state,
        kmForfaitPrice: action.payload,
      };

    case KM_FORFAITS_ACTIONS.KM_FORFAIT_PRICES_MODAL:
      return {
        ...state,
        showModal: false,
        showKmPricesModal: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default vehicleTypeReducer;
