import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import InsuranceForfaits from "./insuranceForfaits/reducers";
import Options from "./options/reducers";
import interventions from "./interventions/reducers";
import kmForfaits from "./kmForfaits/reducers";
import Layout from "./layout/reducers";
import Reservations from "./reservations/reducers";
import Etats from "./etats/reducers";
import stations from "./stations/reducers";
import user from "./users/reducers";
import vehicles from "./vehicle/reducers";
import vehicleInstances from "./vehicleInstance/reducers";
import vehicleTypes from "./vehicleTypes/reducers";

export default combineReducers({
  Auth,
  Layout,
  user,
  vehicles,
  stations,
  vehicleTypes,
  kmForfaits,
  InsuranceForfaits,
  Options,
  Reservations,
  Etats,
  vehicleInstances,
  interventions
});
