import { Etat } from "../../types";
import {
  ETAT_ACTIONS,
  EtatActionType,
} from "./reducers";

/**
 * Load Station action builder.
 * @param Station
 * @returns
 */
export const setEtats = (
  type: Etat[]
): EtatActionType => ({
  type: ETAT_ACTIONS.ETAT_LIST,
  payload: type,
});

/**
 * Error action builder.
 * @param Station
 * @returns
 */
export const setEtatError = (error: any): EtatActionType => ({
  type: ETAT_ACTIONS.ETAT_ERROR,
  payload: error,
});

/**
 * Station loading action builder.
 * @param Station
 * @returns
 */
export const setEtatLoading = (
  isLoading: boolean
): EtatActionType => ({
  type: ETAT_ACTIONS.ETAT_LOADING,
  payload: isLoading,
});

/***
 * Set MCreate modal state
 */
export const setValidateReservationModal = (
  show: boolean
): EtatActionType => ({
  type: ETAT_ACTIONS.ETAT_VALIDATE_MODAL,
  payload: show,
});

/**
 * set update Station modal state.
 * @param show
 * @returns
 */
export const setCancelReservationModal = (
  show: boolean
): EtatActionType => ({
  type: ETAT_ACTIONS.ETAT_CANCEL_MODAL,
  payload: show,
});

export const setReservatioValidateLoading = (
  loading: boolean
): EtatActionType => ({
  type: ETAT_ACTIONS.ETAT_VALIDATE_LOADING,
  payload: loading,
});

export const setDeleteLoading = (
  loading: boolean
): EtatActionType => ({
  type: ETAT_ACTIONS.ETAT_CANCEL_LOADING,
  payload: loading,
});


export const setOpenFactureDommageEditionModal = (
  payload: boolean
  ): EtatActionType => ({
    type: ETAT_ACTIONS.RESERVATION_FACTURE_DOMMAGE_OPEN_CLOSE_NEW_EDIT_MODAL,
    payload
  })