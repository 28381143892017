import { yupResolver } from '@hookform/resolvers/yup';
import FeatherIcons from 'feather-icons-react';
import { useCallback, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { FormInput } from "../../components";
import { InterventionState } from '../../redux/interventions/reducers';
import { createInterventionThunk, deleteInterventionhunk, RevisIOnData } from '../../redux/interventions/thunk';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { VehicleInstanceState } from '../../redux/vehicleInstance/reducers';
import { loadVehicleInstanceThunk } from '../../redux/vehicleInstance/thunk';
import { Revision, VehicleInstance } from "../../types";
import { toISOLocal } from '../../utils/date';

interface Props {
  show: boolean;
  handleClose: () => void;
  revision?: Revision | null;
}

export default function CreateFormDialogIntervention({
  handleClose,
  show,
  revision = null,
}: Props) {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(({ interventions }) => interventions) as InterventionState;
  const { vehicleInstances } = useAppSelector(({ vehicleInstances }) => vehicleInstances) as VehicleInstanceState;

  const pad = (value: any) => {
    return value < 10 ? '0' + value : value;
  }

  const createOffset = (date: any) => {
    var sign = (date.getTimezoneOffset() > 0) ? -1 : +1;
    var offset = Math.abs(date.getTimezoneOffset());
    var hours = pad(Math.floor(offset / 60));
    var minutes = pad(offset % 60);
    return sign * hours;
  }

  const getDateByTimeZone = (date: any) => {
    return date.setHours(date.getHours() + createOffset(date));
  }

  /**
   * Use Form hooks.
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
      desc: yup.string().typeError("Description").required("requis"),
      startAt: yup.date().typeError("Veuillez choisir une date").required("requis"),
      endAt: yup.date().typeError("Veuillez choisir une date").required("requis"),
      vehicleInstanceId: yup.string().oneOf([...vehicleInstances.map(v => v._id)], "Veuillez choisir un vehicule").required("requis"),
    })
  );
  const onSubmit = useCallback((data: RevisIOnData) => {
    let startAt = toISOLocal(data.startAt).split("T");
    let time = startAt[1].split(":")[0]+':'+startAt[1].split(":")[1];
    let endAt = toISOLocal(data.endAt).split("T");
    let endTime = endAt[1].split(":")[0]+':'+endAt[1].split(":")[1];
    let formatStartAt = new Date(getDateByTimeZone(new Date(startAt[0]+" "+time)));
    let formatEndAt = new Date(getDateByTimeZone(new Date(endAt[0]+" "+endTime)));
    data.startAt = formatStartAt;
    data.endAt = formatEndAt;
    if (revision) {
      dispatch(createInterventionThunk({
        id: revision._id,
        ...data
      }));
    } else {
      dispatch(createInterventionThunk(data));
    }
  }, [dispatch, revision]);

  const onDelete = useCallback( () => {
    console.log('revision?._id')
    console.log(revision?._id)
    dispatch(deleteInterventionhunk(revision?._id));
    alert("Intervention supprimé avec succès!")

  }, [dispatch, revision]);

  useEffect(() => {
    dispatch(loadVehicleInstanceThunk({}));
  }, [dispatch]);

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<RevisIOnData>({
    resolver: schemaResolver,
    defaultValues: revision ? { ...revision } : {},
  });
  
  /**
   * Use Form hooks.
   */
  useEffect(() => {
    reset({});
  }, [reset, revision]);

  return (
    <Modal size='xl' show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h3">
          {revision === null && "Ajout d'une revision"}
          {revision !== null && `Edition de la revision`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="authentication-form" noValidate>
          <Row>
            <Col>
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="text"
                name="desc"
                placeholder="Description"
                label={'Description'}
                startIcon={<FeatherIcons icon={'file-text'} className="icon-dual" />}
                defaultValue={revision?.desc}
                containerClass={'mb-3'}
              />
            </Col>
            <Col>
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="select"
                name="vehicleInstanceId"
                placeholder="vehicule"
                label={'vehicule'}
                startIcon={<FeatherIcons icon={'list'} className="icon-dual" />}
                containerClass={'mb-3'}
                defaultValue={revision?.vehicleInstanceId}
              >
                <option disabled>Selectionner</option>
                {vehicleInstances.map((c: VehicleInstance) => (
                  <option key={c._id} value={c._id}>{c.immatriculation}</option>
                ))}
              </FormInput>
            </Col>

          </Row>
          <Row>
            <Col>
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="datetime-local"
                name="startAt"
                placeholder="Debut"
                label={'Debut'}
                startIcon={<FeatherIcons icon={'file-text'} className="icon-dual" />}
                defaultValue={revision?.startAt.toString().split(".")[0]}
                containerClass={'mb-3'}
              />
            </Col>
            <Col>
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="datetime-local"
                name="endAt"
                placeholder="Fin"
                label={'Fin'}
                startIcon={<FeatherIcons icon={'file-text'} className="icon-dual" />}
                defaultValue={revision?.endAt.toString().split(".")[0]}
                containerClass={'mb-3'}
              />
            </Col>
          </Row>


          <Row>
            <Col>
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="checkbox"
                name="isFinished"
                disabled={revision?.isFinished ?? false}
                placeholder="Cloturer l'intervention"
                label={"Cloturer l'intervention"}
                startIcon={<FeatherIcons icon={'file-text'} className="icon-dual" />}
                containerClass={'mb-3'}
              />
            </Col>
          </Row>
          <div className="mb-3 text-center d-flex flex-column gap-2">
            <Button type="submit" variant='primary'
              disabled={isSubmitting}
            >
              Ajouter
            </Button>
            <Button variant="light" onClick={onDelete}>
              Supprimer intervention
            </Button>{' '}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
