import { APICore } from "../../helpers/api/apiCore";
import { Revision } from "../../types";
import {
  setCreateinterventionModal, setDeleteLoading, setStationError,
  setStationLoading, setStations
} from "./actions";


export interface RevisIOnData {
  id?: string;
  isFinished: boolean;
  vehicleInstanceId: string;
  startAt: Date;
  endAt: Date;
  desc: string;
}

/**
 * Load cities thunk.
 *
 * @param param0
 * @returns
 */
export const loadInterventionsThunk = (filter: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setStationLoading(true));

      const resp = await api.get("/admin/revisions");

      if (resp.data?.status === "OK") {
        if(filter.immatriculation) {
          const filterdInterventions = resp.data.result.filter((o: Revision) => o.vehicleInstance.immatriculation.includes(filter.immatriculation));
          dispatch(setStations(filterdInterventions));
        } else {
          dispatch(setStations(resp.data.result));
        }
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setStationError(err));
    }
  };
};

/**
 * Create city thunk.
 *
 * @param param0
 * @returns
 */
export const createInterventionThunk = (data: RevisIOnData) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const url = data.id ? `/admin/revisions/${data.id}` : "/admin/revisions";
      const resp = data.id ? await api.update(url, data) : await api.create(url, data);

      if (resp.data) {
        dispatch(loadInterventionsThunk({}));
        dispatch(setCreateinterventionModal(false));
      }
    } catch (error: any) {
      console.log({ error });

      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setStationError(err));
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const deleteInterventionhunk = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setDeleteLoading(true));
      const resp = await api.delete(`/admin/revisions/${id}`);

      if (resp.data?.status === "OK") {
        dispatch(setCreateinterventionModal(false));
        dispatch(loadInterventionsThunk({}));
      }

      dispatch(setDeleteLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setDeleteLoading(false));
    }
  };
};

// export const createOrUpdateServiceOptions = ({}: ServiceOptionsData) => {};
