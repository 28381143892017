import PageTitle from "../../components/PageTitle";
import { Card, Col, Row } from "react-bootstrap";
import React, { Component } from "react";
import { render } from 'react-dom'
import CustomTimeline from './CustomTimeline'
import 'react-calendar-timeline/lib/Timeline.css'

function ThePlanning() {


  return (
    <>
     <PageTitle
        breadCrumbItems={[]}
        title={"Planning"}
      />
      <Row>
      <Col>
      {/* <Card> 
      <Card.Body> */}
        <div style={{'minHeight': '300px'}}>
        <CustomTimeline />
        </div>
      {/* </Card.Body>
      </Card> */}
      </Col>
      </Row>
    </>
  );
}

export default ThePlanning;
