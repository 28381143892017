import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Reservation, Station, Vehicle, VehicleInstance } from "../../types";
import ReservationCard from "./ReservationCard";
import { useForm } from "react-hook-form";
import { VehicleState } from "../../redux/vehicle/reducers";
import { VehicleInstanceState } from "../../redux/vehicleInstance/reducers";
import { StationState } from "../../redux/stations/reducers";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { createVehicleInstanceThunk, loadVehicleInstanceThunk, updateVehicleInstanceThunk } from "../../redux/vehicleInstance/thunk";
import { setVehicleInstanceError } from '../../redux/vehicleInstance/actions';
import { FormInput } from "../../components";
import FeatherIcons from 'feather-icons-react';
import { loadVehicleAvailblePerStation } from "../../redux/vehicle/thunk";
import { updateReservation } from "../../redux/reservations/thunk";
import { RevisIOnData, createInterventionThunk } from "../../redux/interventions/thunk";
import { toISOLocal } from "../../utils/date";
// import React, { useEffect, useState, useCallback } from "react";



interface Props {
  show: boolean;
  handleClose: () => void;
  reservation: Reservation;
  vehicle?: VehicleInstance | null;
}

export default function DemenagerVehicleInstance({
  handleClose,
  show,
  reservation,
  vehicle = null,
}: Props) {
  const dispatch = useAppDispatch();

  // const dispatch = useAppDispatch();
  const { vehicles } = useAppSelector(({ vehicles }) => vehicles) as VehicleState;
  const { isLoading, error } = useAppSelector(({ vehicleInstances }) => vehicleInstances) as VehicleInstanceState;
  const { stations } = useAppSelector(({ stations }) => stations) as StationState;
  const {  availableVehiclesPerStation, listGearBoxes, listImmatriculations } = useAppSelector(({ vehicles }) => vehicles) as VehicleState;
  const { vehicleInstances } = useAppSelector(({ vehicleInstances }) => vehicleInstances) as VehicleInstanceState;

  const [loadingVehicle, setLoadingVehicle] = useState(false); // État local pour contrôler l'affichage du loader

  const [lesInstancesVehicles, setLesInstancesVehicles] = useState(null);

  const [changed, setChanged] = useState(0);  
  const [defaultEndDate, setDefaultEndDate] = useState<string>("");
  const [defaultStartDate, setDefaultStartDate] = useState<string>("");
  const [dateAreSet, setDateAreSet] = useState(false); 

  const [cantBeSubmit, setCantBeSubmit] = useState(true); 


  const schemaResolver = yupResolver(
    yup.object().shape({
      // immatriculation: yup.string().typeError("Veuillez saisir un texte").required("requis"),
      // unLockCentralId: yup.string().typeError("Veuillez saisir un texte").required("requis"),
      // vehicleId: yup.string().oneOf(vehicles?.map((v) => v._id), "Veuillez choisir un modele"),
      // stationId: yup.string().oneOf(stations.map((v) => v._id), "Veuillez choisir une station"),
      // inServiceSince: yup.date().typeError("Veuillez choisir une date").required("requis"),
    })
  );

  useEffect(() => {
    if (reservation.endAt) {
      // Ajoutez 3 heures à la date de fin de la réservation et appliquez le fuseau horaire de la France
      const endDatePlus3Hours = moment(reservation.endAt).add(1, 'hours').utcOffset('+0200').format("YYYY-MM-DDTHH:mm");
      setDefaultEndDate(endDatePlus3Hours);
    }
    if (reservation.startAt) {
      // Ajoutez 3 heures à la date de fin de la réservation et appliquez le fuseau horaire de la France
      const startDateMoins3Hours = moment(reservation.startAt).add(-5, 'hours').format("YYYY-MM-DDTHH:mm");
      setDefaultStartDate(startDateMoins3Hours);
    }
    if (reservation.startAt && reservation.endAt) {
      setDateAreSet(true)
    }
  }, [reservation.endAt, reservation.startAt]);


  

  let formattedDateDebut = "";
  let formattedDateFin = "";
  let formattedDateStart = "";
  let formattedDateEnd = "";

  // var dateFinStation = reservation?.endAt ? new Date(reservation?.endAt.getTime() + 3 * 60 * 60 * 1000) : undefined;
if(dateAreSet ){
//   const dateDebut = vehicle.station2debut ? moment(vehicle.station2debut) : moment(new Date());
//   // formattedDateDebut = `${dateDebut.getFullYear()}-${(dateDebut.getMonth() + 1).toString().padStart(2, '0')}-${dateDebut.getDate().toString().padStart(2, '0')}T${dateDebut.getHours().toString().padStart(2, '0')}:${dateDebut.getMinutes().toString().padStart(2, '0')}`;

//   formattedDateDebut = dateDebut.format("YYYY-MM-DDTHH:mm")
//   const dateFin = vehicle.station2fin ? new Date(vehicle.station2fin) :  new Date();
//   formattedDateFin = `${dateFin.getFullYear()}-${(dateFin.getMonth() + 1).toString().padStart(2, '0')}-${dateFin.getDate().toString().padStart(2, '0')}T${dateFin.getHours().toString().padStart(2, '0')}:${dateFin.getMinutes().toString().padStart(2, '0')}`;

// console.log('formattedDateDebut')
// console.log(dateDebut)
// console.log(formattedDateDebut)

// console.log('formattedDateFin')
// console.log(dateFin)
// console.log(formattedDateFin) 

const dateStart = moment(defaultStartDate);
   formattedDateStart = dateStart.format("DD/MM/YYYY HH:mm");
console.log('formattedDateStart', formattedDateStart)

const dateEnd = moment(defaultEndDate);
 formattedDateEnd = dateEnd.format("DD/MM/YYYY HH:mm");
console.log('formattedDateEnd', formattedDateEnd)

}

const pad = (value: any) => {
  return value < 10 ? '0' + value : value;
}

const createOffset = (date: any) => {
  var sign = (date.getTimezoneOffset() > 0) ? -1 : +1;
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = pad(Math.floor(offset / 60));
  var minutes = pad(offset % 60);
  return sign * hours;
}

const getDateByTimeZone = (date: any) => {
  return date.setHours(date.getHours() + createOffset(date));
}



// const onSubmitVehicle = useCallback((data) => {

//   if(data?.immatriculation && data.immatriculation != ''){

//   }
//   console.log('vehicle update')
//   console.log( data);
//   console.log('reservation update')
//   console.log( reservation);
//   data.instanceId = data?.immatriculation;
//   data.vehicleId = reservation?.vehicleInstance.vehicle?._id;
//   if (reservation?.vehicleInstance?.vehicle) {
//     console.log('vehicle update in')
//     // console.log(vehicle)
//     dispatch(updateVehicleInstanceThunk({
//       ...data,
//       id: data?.immatriculation,
//       immatriculation: data?.immatrivulation0
//     }));
//   } else {
//     dispatch(createVehicleInstanceThunk({
//       ...data,
//     }));
//   }
//   const data1 = data;
//   data1.id = reservation?._id;

//   console.log('reservation 007 update')
//   console.log( data1);
  

//   dispatch(updateReservation(reservation?._id as string, data1));

//   // let startAt = toISOLocal(data.station2debut).split("T");
//     // let time = startAt[1].split(":")[0]+':'+startAt[1].split(":")[1];
//     // let endAt = toISOLocal(reservation?.startAt).split("T");
//     // let endTime = endAt[1].split(":")[0]+':'+endAt[1].split(":")[1];
//     let formatStartAt = new Date(data.station2debut);
//     let formatEndAt = new Date(reservation?.startAt);
//   const data2: RevisIOnData = {
//     isFinished: false,
//     vehicleInstanceId: data?.instanceId,
//     startAt: formatStartAt,
//     endAt: formatEndAt,
//     desc: "Déménagement du véhicule"
//   };

//     dispatch(createInterventionThunk(data2));

//     // let startAt1 = toISOLocal(reservation?.endAt).split("T");
//     // let time1 = startAt1[1].split(":")[0]+':'+startAt1[1].split(":")[1];
//     // let endAt1 = toISOLocal(data.station2fin).split("T");
//     // let endTime1 = endAt1[1].split(":")[0]+':'+endAt1[1].split(":")[1];
//     let formatStartAt1 = new Date(reservation?.endAt);
//     let formatEndAt1 = new Date(data.station2fin);
//   const data3: RevisIOnData = {
//     isFinished: false,
//     vehicleInstanceId: data?.instanceId,
//     startAt: formatStartAt1,
//     endAt: formatEndAt1,
//     desc: "Déménagement du véhicule"
//   };

//     dispatch(createInterventionThunk(data3));

//     alert("Déménagement du véhicule effectué avec succès!")

//     // window.location.reload();

// }, [dispatch]);


const onSubmitVehicle = useCallback(async (data) => {
  try {
    if (data?.immatriculation && data.immatriculation != '') {
      // Additional checks or operations if necessary
    }
    
    console.log('vehicle update', data);
    console.log('reservation update', reservation);

    data.instanceId = data?.immatriculation;
    data.vehicleId = reservation?.vehicleInstance.vehicle?._id;

    let vehicleInstancePromise;
    if (reservation?.vehicleInstance?.vehicle) {
      console.log('vehicle update in');
      vehicleInstancePromise = dispatch(updateVehicleInstanceThunk({
        ...data,
        id: data?.immatriculation,
        immatriculation: data?.immatriculation,
      }));
    } else {
      vehicleInstancePromise = dispatch(createVehicleInstanceThunk({ ...data }));
    }

    const data1 = { ...data, id: reservation?._id };
    console.log('reservation 007 update', data1);
    const reservationPromise = dispatch(updateReservation(reservation?._id as string, data1));

    const formatStartAt = new Date(data.station2debut);
    const formatEndAt = new Date(reservation?.startAt);
    const data2: RevisIOnData = {
      isFinished: false,
      vehicleInstanceId: data?.instanceId,
      startAt: formatStartAt,
      endAt: formatEndAt,
      desc: "Déménagement du véhicule",
    };
    const interventionPromise1 = dispatch(createInterventionThunk(data2));

    const formatStartAt1 = new Date(reservation?.endAt);
    const formatEndAt1 = new Date(data.station2fin);
    const data3: RevisIOnData = {
      isFinished: false,
      vehicleInstanceId: data?.instanceId,
      startAt: formatStartAt1,
      endAt: formatEndAt1,
      desc: "Déménagement du véhicule",
    };
    const interventionPromise2 = dispatch(createInterventionThunk(data3));

    await Promise.all([vehicleInstancePromise, reservationPromise, interventionPromise1, interventionPromise2]);

    alert("Déménagement du véhicule effectué avec succès!");

    window.location.reload();
  } catch (error) {
    console.error('Error during submission:', error);
  }
}, [dispatch, reservation]);


const {
  handleSubmit,
  register,
  control,
  reset,
  watch,
  formState: { errors, isSubmitting },
} = useForm<{
  immatriculation: string | null;
  // immatriculation0: string | null;
  unLockCentralId: string | null;
  vehicleId: string | null;
  stationId: string | null;
  position: string | null;
  hasClimatisation: boolean | null;
  inServiceSince: Date | null; //Date de mise en circulation
  hasGps: boolean | null;
  hasUsb: boolean | null;
  hasBluetooth: boolean | null;
  hasAndroidAuto: boolean | null;
  hasAppleCarPlay: boolean | null;// "essence" | "gas-oil"
  hasNotBoitier: boolean | null;
  carId: string | null;
  stationSecondaire: string | null;
  station2debut: Date | null;
  station2fin: Date | null;
  station: string | null;
  vehicule: string | null;
  debut: Date | null;
  fin: Date | null;
}>({
  resolver: schemaResolver,
  defaultValues: vehicle !== null ? {
    immatriculation: vehicle?.immatriculation,
    unLockCentralId: vehicle?.unLockCentralId,
    // vehicleId: vehicle?.vehicleId,
    stationId: vehicle?.stationId,
    position: vehicle?.position,
    hasClimatisation: vehicle?.hasClimatisation,
    hasGps: vehicle?.hasGps,
    hasUsb: vehicle?.hasUsb,
    inServiceSince: vehicle?.inServiceSince,
    hasBluetooth: vehicle?.hasBluetooth,
    hasAndroidAuto: vehicle?.hasAndroidAuto,
    hasAppleCarPlay: vehicle?.hasAppleCarPlay,
    hasNotBoitier: vehicle?.hasNotBoitier,
    carId: vehicle?.carId,
    stationSecondaire: vehicle?.stationSecondaire,
    station2debut: new Date(defaultStartDate),
    station2fin:  new Date(defaultEndDate),
  } : {}
});

// useEffect(() => {
//   reset({
//     immatriculation: vehicle?.immatriculation,
//     unLockCentralId: vehicle?.unLockCentralId,
//     vehicleId: vehicle?.vehicleId,
//     hasClimatisation: vehicle?.hasClimatisation,
//     stationId: vehicle?.stationId,
//     position: vehicle?.position,
//     hasGps: vehicle?.hasGps,
//     inServiceSince: vehicle?.inServiceSince,
//     hasUsb: vehicle?.hasUsb,
//     hasBluetooth: vehicle?.hasBluetooth,
//     hasAndroidAuto: vehicle?.hasAndroidAuto,
//     hasAppleCarPlay: vehicle?.hasAppleCarPlay,
//     hasNotBoitier: vehicle?.hasNotBoitier,
//     carId: vehicle?.carId,
//     stationSecondaire: vehicle?.stationSecondaire,
//     station2debut: vehicle?.station2debut,
//     station2fin: vehicle?.station2fin ? new Date(vehicle?.station2fin.getTime() + 3 * 60 * 60 * 1000) : undefined,
//   });
//   dispatch(setVehicleInstanceError(null));
// }, [reset, vehicle]);

useEffect(() => {
  const subscribe = watch((data, { name, type }) => {
    console.log(data);
    if (data.stationId) {
      setLoadingVehicle(true)
      // dispatch(loadOptionsBasedStationThunk(data.station as string))
      // dispatch(loadVehicleAvailblePerStation(data.station as string))
      console.log("data 001")
      console.log(data)
      console.log("data stationId", data.stationId)
      console.log("data debut", reservation.startAt)
      console.log("data fin", reservation.endAt)
      const maStation = data.stationId;
      dispatch(loadVehicleAvailblePerStation({ station: maStation as string, from: (reservation.startAt as unknown as string), to: (reservation.endAt as unknown as string) }))

      setChanged(1)
      console.log("Je suis passé")
      console.log("stationChanged")
      console.log(availableVehiclesPerStation);
      setLoadingVehicle(false)
    }
    if(data.vehicule){
      const  vehicle  = (availableVehiclesPerStation).find(vehicleInstance => (vehicleInstance?._id === (data.vehicule))) as any
      dispatch(loadVehicleInstanceThunk({station: data.station}));
      // dispatch(loadVehicleImmatriculation({ name: vehicle?.name, brand: vehicle?.brand, gearbox: vehicle?.gearbox, station: data?.station, from: (data?.debut as unknown as string), to: (data?.fin as unknown as string) }))
      
      console.log("vehicle")
      console.log("Je suis passé vehicule")
      console.log(vehicle)
      let mesInstances = vehicle?.vehicleInstances;
      setLesInstancesVehicles(mesInstances)
      setCantBeSubmit(false)
      console.log("mesInstances")
      console.log(lesInstancesVehicles)
      console.log("vehicleInstances")
      console.log(vehicleInstances)

      // let vehicleInstance = vehicleInstances.find(instance => instance.vehicle._id === vehicle._id) as VehicleInstance;
      // console.log('vehicleInstance', vehicleInstance)
    
    }
  });
}, [availableVehiclesPerStation, dispatch, changed,  watch])



  return (
    <div>
      <h4>
        Remplacer le véhicule
      </h4>
      <form onSubmit={handleSubmit(onSubmitVehicle)} className="authentication-form" noValidate>
      {error && (
            <Alert variant="danger" className="my-2">
              {/* @ts-ignore */}
              {error}
            </Alert>
          )}
          <Row>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="select"
                    name="stationId"
                    placeholder="Station"
                    label={'Station'}
                    containerClass={'mb-1'}
                  >
                    <option disabled>Selectionner</option>
                    {/* <option key={reservation.vehicleInstance?.stationId} value={reservation.vehicleInstance?.station?._id} selected>{reservation.vehicleInstance?.station?.name}</option> */}
                    {stations?.map((c: Station) => {
                      if(c) {
                        return (
                          c._id == reservation.vehicleInstance?.station._id ?
                            <option key={c._id} value={c._id} selected>{c.name}</option>
                          :
                            <option key={c._id} value={c._id}>{c.name}</option>
                        )
                      }
                    })}
                  </FormInput>
                </Col>

                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="select"
                    name="stationSecondaire"
                    placeholder="Changer de station"
                    label={'Changer de station'}
                    containerClass={'mb-1'}
                  >
                    <option disabled>Selectionner</option>
                    {/* <option key={reservation.vehicleInstance?.station2debut <= reservation?.startAt && reservation?.vehicleInstance?.station2fin >= reservation?.endAt ?
              reservation?.vehicleInstance?.station2?._id :
              reservation.vehicleInstance?.station?._id} 
              value={reservation.vehicleInstance?.station2debut <= reservation?.startAt && reservation?.vehicleInstance?.station2fin >= reservation?.endAt ?
                reservation?.vehicleInstance?.station2?._id :
                reservation.vehicleInstance?.station?._id} selected>
                  {reservation.vehicleInstance?.station2debut <= reservation?.startAt && reservation?.vehicleInstance?.station2fin >= reservation?.endAt ?
              reservation?.vehicleInstance?.station2?.name :
              reservation.vehicleInstance?.station?.name}</option> */}
                    {stations?.map((c: Station) => {
                      if(c) {
                        return (
                          c._id == reservation.vehicleInstance?.station._id ?
                            <option key={c._id} value={c._id} selected>{c.name}</option>
                          :
                            <option key={c._id} value={c._id}>{c.name}</option>
                        )
                      }
                    })}
                  </FormInput>
                </Col>
                
              </Row>

            {dateAreSet ? 
              <Row>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="datetime-local"
                    name="station2debut"
                    placeholder="Date de début"
                    label={'Date de début'}
                    containerClass={'mb-1'}
                    defaultValue={defaultStartDate || formattedDateStart}
                  />
                </Col>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="datetime-local"
                    name="station2fin"
                    placeholder="Date de fin"
                    label={'Date de fin'}
                    containerClass={'mb-1'}
                    defaultValue={defaultEndDate || formattedDateEnd}
                  />
                </Col>
              </Row>
              : "Loading..."
            }
              <Row>
                {loadingVehicle == true ? "Loading..." : 
              <Col>
                {loadingVehicle}
                <FormInput
                          register={register}
                          control={control}
                          errors={errors}
                          type="select"
                          name="vehicule"
                          placeholder="Véhicule"
                          label={'Véhicule'}
                          containerClass={'mb-1'}
                          // id="state"
                        >
                        <option disabled>Selectionner</option>
                        {/* {reservation.vehicleInstance?.vehicle && (
                        <option key={reservation.vehicleInstance?.vehicle?._id} value={reservation.vehicleInstance?.vehicle?._id} selected>{reservation.vehicleInstance?.vehicle?.name}-{reservation.vehicleInstance?.vehicle?.brand} {reservation.vehicleInstance?.vehicle?.gearbox}</option>
                        )} */}
                        {availableVehiclesPerStation?.map((c: Vehicle) => {
                          if(c) {
                            return (
                              c._id == reservation.vehicleInstance?.vehicle._id ?
                                <option key={c._id} value={c._id} selected>{c.name}-{c.brand} {c.gearbox}</option>
                              :
                                <option key={c._id} value={c._id}>{c.name}-{c.brand} {c.gearbox}</option>
                            )
                          }
                        })}
                </FormInput>
                        
                      </Col>
                      }
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="select"
                    name="immatriculation"
                    placeholder="Immatriculation"
                    label={'Immatriculation'}
                    containerClass={'mb-1'}
                  >
                    <option disabled>Selectionner</option>
                    {/* <option key={reservation.vehicleInstance?._id} value={reservation.vehicleInstance?._id} selected>{reservation.vehicleInstance?.immatriculation}</option> */}
                    {lesInstancesVehicles?.map((c: VehicleInstance) => {
                      if(c) {
                        return (
                          c._id == reservation.vehicleInstance?._id ?
                            <option key={c._id} value={c._id} selected>{c.immatriculation}</option>
                          :
                            <option key={c._id} value={c._id}>{c.immatriculation}</option>
                        )
                      }
                    })}
                  </FormInput>
                </Col>
              </Row>
              
          <div className="mb-3 text-center d-flex flex-column gap-2 mt-3">
            <Button type="submit" variant='primary'
              disabled={isLoading || isSubmitting || cantBeSubmit}
            >
              {(isLoading || isSubmitting) ? (
                <div className="spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <>
                  Valider
                </>
              )}
            </Button>
            <Button variant="light" onClick={handleClose}>
              Annuler
            </Button>{' '}
          </div>
          
      </form>
    </div>
  )
}
