import { string } from "yup";
import { APICore } from "../../helpers/api/apiCore";
import {
  setReservationLoading,
  setReservationError,
  setReservationValidateStatus,
  setReservationCancelStatus,
  setReservatioCancelLoading,
  setReservations,
  setReservatioValidateLoading,
  setValidateReservationModal,
  setCancelReservationModal,
  setReservationUpdateLoading,
  setEtas,
  setDeleteLoading,
  setOpenReservationEditionModal,
  setReservationsRequestList,
  setReservationHistoryList,
  setReservation,
  setFacturesDownloadList,
  setPlanning
} from "./actions";
import { Reservation } from "../../types";
import { setVehicleLoading } from "../vehicle/actions";

export interface Reservationsdata {
  name: string;
  desc: string;
  price: string;
  icon: string;
}

export const createReservationThunk = (data: any, reset: CallableFunction) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setVehicleLoading(true));
      const resp = await api.create(`/reservations/external`, data);

      if (resp.data?.status === "OK") {
        data.loadReservationList ?
        dispatch(loadReservationsThunk({})) : dispatch(loadReservationsRequestsThunk({}));
        reset()
        dispatch(loadReservationsThunk({}));
        dispatch(setOpenReservationEditionModal(false))
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }
    } finally {
        dispatch(setVehicleLoading(false));
    }
  };
};

/**
 * Load insurance forfaits thunk.
 *
 * @param param0
 * @returns
 */
export const loadReservationsThunk = (filter: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      console.log(filter.immatriculation)
      dispatch(setReservationLoading(true));
      var filtre = '';
      if(filter.status && !filter.immatriculation){
        filtre = `?status=${filter.status}`;
      }
      else if(filter.status && filter.immatriculation){
        filtre = `?status=${filter.status}&immatriculation=${filter.immatriculation}`;
      }
      else if(!filter.status && filter.immatriculation){
        filtre = `?immatriculation=${filter.immatriculation}`;
      }
      else if(filter.startDate) {
        filtre = `?startDate=${filter.startDate}`;
      }
      else if(!filter.status && !filter.immatriculation){
        filtre = ``;
      }
      // const resp = await api.get(`/admin/reservations${filter.status ? `?status=${filter.status}` : ''}`);
      console.log("debut req")
      console.log(filtre)
      const resp = await api.get(`/admin/reservations${filtre}`);
      console.log("fin req")
      console.log(resp.data.result)
      if (resp.data?.status === "OK") {
        if(filter.immatriculation) {
          console.log(true)
          // const filterdReservation = resp.data.result.filter((o: Reservation) => o.reservationNumber == filter.immatriculation || o.vehicleInstance.immatriculation.includes(filter.immatriculation) || o.user.name.toLowerCase().includes(filter.immatriculation.toLowerCase()) || o.user.firstName.toLowerCase().includes(filter.immatriculation.toLowerCase()) || o.user.phone === filter.immatriculation || o.user.email.toLowerCase().includes(filter.immatriculation.toLowerCase()));
          const filterdReservation = resp.data.result.filter((o: Reservation) =>
          o.reservationNumber == filter.immatriculation ||
          o.vehicleInstance.immatriculation.includes(filter.immatriculation) ||
          o.user.name.toLowerCase().includes(filter.immatriculation.toLowerCase()) ||
          o.user.firstName.toLowerCase().includes(filter.immatriculation.toLowerCase()) ||
          o.user.phone === filter.immatriculation ||
          o.user.email.toLowerCase().includes(filter.immatriculation.toLowerCase()) ||
          o.clientEmail?.toLowerCase().includes(filter.immatriculation.toLowerCase())
        );
          console.log('bob b')
          dispatch(setReservations(filterdReservation));
        } else {
          dispatch(setReservations(resp.data.result));
        }
        return;
      }

      dispatch(setReservationLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setReservationError(err));
    }
  };
};

export const loadReservationsRequestsThunk = (filter: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setReservationLoading(true));

      const resp = await api.get(`/reservations/reservations-requests${filter.status ? `?status=${filter.status}` : ''}`);

      if (resp.data?.status === "OK") {
        dispatch(setReservationsRequestList(resp.data.result));
        return;
      }

      dispatch(setReservationLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setReservationError(err));
    }
  };
};

export const loadFacturesDownloadThunk = (filter: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setReservationLoading(true));

      const resp = await api.get(`/admin/reservations/facture-list`);
      console.log("erat")
      if (resp.data?.status === "OK") {
        console.log("erat")
        dispatch(setFacturesDownloadList(resp.data.result));
        return;
      }

      dispatch(setReservationLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setReservationError(err));
    }
  };
};

export const loadPlanningThunk = (filter: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setReservationLoading(true));

      console.log("start Planning")
      const resp = await  api.get("/admin/reservations/planning-list");
      console.log("eratPLannings", resp)
      console.log(resp)
      
      // api.get(`/admin/reservations/facture-list`);
      console.log("erat")
      if (resp.data?.status === "OK") {
        console.log("erat")
        dispatch(setPlanning(resp.data.data));
        return;
      }

      dispatch(setReservationLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setReservationError(err));
    }
  };
};



export const loadReservationsHistoryThunk = (reservationId: string)=> {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setReservationLoading(true))
      const resp = await api.get(`/admin/reservations/get-reservation-history/${reservationId}`);

      if (resp.data?.status === "OK") {
        dispatch(setReservationHistoryList(resp.data.result.map((history: any) => history.state)));
        return;
      }

      dispatch(setReservationLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setReservationError(err));
    }
  };
};


export const loadReservationThunk = (reservationId: string)=> {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      console.log(reservationId)
      dispatch(setReservationLoading(true))
      const resp = await api.get(`/admin/reservations/get-reservation/${reservationId}`);

      if (resp.data?.status === "OK") {
        dispatch(setReservation(resp.data.result));
        return;
      }

      dispatch(setReservationLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setReservationError(err));
    }
  };
};

export const makeFactureThunk = ()=> {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      console.log("req")
      dispatch(setReservationLoading(true))
      const resp = await api.get(`/admin/reservations/makefacture`);

      if (resp.data?.status === "OK") {
        dispatch(setReservation(resp.data.result));
        return;
      }

      dispatch(setReservationLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setReservationError(err));
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const validateReservationThunk = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setReservatioValidateLoading(true));
      const resp = await api.update(`/admin/reservations/${id}/validate`, {});

      if (resp.data?.status === "OK") {
        alert("Réservation confirmée");
        dispatch(
          setReservationValidateStatus({
            status: "SUCCESS",
            msg: resp.data.result,
          })
        );
        dispatch(setValidateReservationModal(false));
        dispatch(loadReservationsThunk({}));
      }
      dispatch(setReservatioValidateLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setReservatioValidateLoading(false));
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const cancelReservationThunk = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setReservatioCancelLoading(true));
      const resp = await api.update(`/admin/reservations/${id}/cancel`, {});

      if (resp.data?.status === "OK") {
        dispatch(
          setReservationCancelStatus({
            status: "SUCCESS",
            msg: resp.data.result,
          })
        );
        dispatch(setCancelReservationModal(false));
        dispatch(loadReservationsThunk({}));
      }

      dispatch(setReservatioCancelLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setReservatioCancelLoading(false));
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const updateReservation = (id: string, data: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setReservationUpdateLoading(true));
      const resp = await api.update(`/admin/reservations/${id}/update`, data);

      if (resp.data?.status === "OK") {
        /*dispatch(
          setReservationCancelStatus({
            status: "SUCCESS",
            msg: resp.data.result,
          })
        );*/
        dispatch(loadReservationsThunk({}));
      }

      dispatch(setReservationUpdateLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setReservationUpdateLoading(false));
    }
  };
};


export const updateReservationExterne = (id: string, data: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      console.log('data update', data)
      dispatch(setReservationUpdateLoading(true));
      const resp = await api.update(`/admin/reservations/${id}/update-reservation`, data);

      if (resp.data?.status === "OK") {
        /*dispatch(
          setReservationCancelStatus({
            status: "SUCCESS",
            msg: resp.data.result,
          })
        );*/
        dispatch(loadReservationsThunk({}));
      }

      dispatch(setReservationUpdateLoading(false));
      dispatch(setOpenReservationEditionModal(false))
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setReservationUpdateLoading(false));
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const reservationEtats = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    console.log(id)
    try {
      const resp = await api.get(`/admin/reservations/report/reservation/${id}`);

      if (resp.data?.status === "OK") {
        dispatch(setEtas(resp.data.result));
      }
      return;
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        console.log(err);
      }
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const deleteReservationThunk = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setDeleteLoading(true));
      const resp = await api.delete(`/admin/reservations/${id}`);

      if (resp.data?.status === "OK") {
        alert("Reservation supprimée");
        dispatch(setCancelReservationModal(false));
        dispatch(loadReservationsThunk({}));
      }

      dispatch(setDeleteLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setDeleteLoading(false));
    }
  };
};

export const createRContraventionThunk =
// (data: any)
// ({
//   contraventionType,
//   photo,
//   montant,
//   reservationId,
// }: any)
(data: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      // const filePath = URL.createObjectURL(data.files[0]);
      console.log("file012")
      // console.log(data)
      console.log("form data")
      console.log(data)
      dispatch(setVehicleLoading(true));
      // const resp = await api.create(`/admin/reservations/createcontravention`, data);

      const formData = new FormData();

      // formData.append("contraventionType", contraventionType);
      // formData.append("montant", montant);
      // formData.append("reservationId",reservationId);
      // formData.append("photoUrl", photo);
      // if (data.files) {
      //   formData.append("files", data.files);
      // }

      // const resp = await api.create(`/admin/reservations/createcontravention`, data, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
      console.log(formData)
      const resp = await api.create(`/admin/reservations/createcontravention`, data, {
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });

debugger
      if (resp.data?.status === "OK") {
        // data.loadReservationList ?
        // dispatch(loadReservationsThunk({})) : dispatch(loadReservationsRequestsThunk({}));

        dispatch(loadReservationsThunk({}));
        dispatch(setOpenReservationEditionModal(false))
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }
    } finally {
        dispatch(setVehicleLoading(false));
    }
  };
};

export const createFactureDomageThunk = (data: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setVehicleLoading(true));
      const resp = await api.create(`/admin/reservations/facturedommage`, data);

      if (resp.data?.status === "OK") {
        data.loadReservationList ?
        dispatch(loadReservationsThunk({})) : dispatch(loadReservationsRequestsThunk({}));

        dispatch(loadReservationsThunk({}));
        dispatch(setOpenReservationEditionModal(false))
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }
    } finally {
        dispatch(setVehicleLoading(false));
    }
  };
};


export const makeFatureByIdThunk = (data: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setVehicleLoading(true));
      console.log('viens k45')
      console.log(data)
      const resp = await api.create(`/admin/reservations/makefacturebyid`, data);

      if (resp.data?.status === "OK") {
        data.loadReservationList ?
        dispatch(loadReservationsThunk({})) : dispatch(loadReservationsRequestsThunk({}));

        dispatch(loadReservationsThunk({}));
        dispatch(setOpenReservationEditionModal(false))
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }
    } finally {
        dispatch(setVehicleLoading(false));
    }
  };
};


export const getPlanningThunk = (filter: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setReservationLoading(true));

      const resp = await api.get("/vehicles/gearboxes/asdr");
      console.log("erat")
      if (resp.data?.status === "OK") {
        console.log("erat")
        dispatch(setPlanning(resp.data.result));
        return;
      }

      dispatch(setReservationLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setReservationError(err));
    }
  };
};

export const createFactureRemboursementThunk = (data: any) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      console.log("e")
      dispatch(setVehicleLoading(true));
      const resp = await api.create(`/admin/reservations/factureremboursement`, data);

      if (resp.data?.status === "OK") {
        data.loadReservationList ?
        dispatch(loadReservationsThunk({})) : dispatch(loadReservationsRequestsThunk({}));

        dispatch(loadReservationsThunk({}));
        dispatch(setOpenReservationEditionModal(false))
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }
    } finally {
        dispatch(setVehicleLoading(false));
    }
  };
};
