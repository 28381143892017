import { Vehicle } from "../../types";
import { VEHICLE_ACTIONS, VehicleActionType } from "./reducers";

/**
 * Load Vehicle action builder.
 * @param Vehicle
 * @returns
 */
export const setVehicles = (Vehicle: Vehicle[]): VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_LIST,
  payload: Vehicle,
});

export const setAvailableVehiculesPerStation = (vehicle:  Vehicle[]): VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_AVAILABLE_PER_STATION_LIST,
  payload: vehicle
})

export const setVehicleGearBoxes = (listGearBoxes: string[]): VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_GEAR_BOXES,
  payload: listGearBoxes
})


export const setVehiculeImmatriculationList = (listImmatriculation: string[]): VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_LIST_IMMATRICULATION,
  payload: listImmatriculation
})

/**
 * Error action builder.
 * @param Vehicle
 * @returns
 */
 export const setVehicleError = (error: any): VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_ERROR,
  payload: error,
});

/**
 * Vehicle loading action builder.
 * @param Vehicle
 * @returns
 */
 export const setVehicleLoading = (isLoading: boolean): VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_LOADING,
  payload: isLoading,
});

/***
 * Set MCreate modal state
 */
export const setCreateVehicleModal = (show: boolean) : VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_CREATE_MODAL,
  payload: show,
})

/***
 * Set MCreate modal state
 */
export const setPricesVehicleModal = (show: boolean) : VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_PRICES_MODAL,
  payload: show,
})

/**
 * set update Vehicle modal state.
 * @param show 
 * @returns 
 */
export const setUpdateVehicleModal = (show: boolean) : VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_UPDATE_MODAL,
  payload: show,
})

/***
 * Set MCreate modal state
 */
export const setDeleteLoading = (show: boolean) : VehicleActionType => ({
  type: VEHICLE_ACTIONS.VEHICLE_IS_DELETED_LOADING,
  payload: show,
})



