import { string } from "yup";
import { APICore } from "../../helpers/api/apiCore";
import {
  setEtatLoading,
  setEtatError,
  setEtats,
  setReservatioValidateLoading,
  setValidateReservationModal,
  setCancelReservationModal,
  setDeleteLoading,
} from "./actions";

export interface Etatsdata {
  name: string;
  desc: string;
  price: string;
  icon: string;
}

/**
 * Load insurance forfaits thunk.
 *
 * @param param0
 * @returns
 */
export const loadEtatsThunk = () => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setEtatLoading(true));

      const resp = await api.get("/admin/reservations/all/report");
      if (resp.data?.status === "OK") {
        dispatch(setEtats(resp.data.result));
        return;
      }

      dispatch(setEtatLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setEtatError(err));
    }
  };
};

export const loadDommagesThunk = (reservationId: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setEtatLoading(true));
      console.log(reservationId)

      const resp = await api.get(`/admin/reservations/all/dommages/${reservationId}`);
      // const resp = await api.get(`/admin/reservations/get-reservation/${reservationId}`);
      if (resp.data?.status === "OK") {
        dispatch(setEtats(resp.data.result));
        return;
      }

      dispatch(setEtatLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setEtatError(err));
    }
  };
};
/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const deleteEtatThunk = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setDeleteLoading(true));
      const resp = await api.delete(`/admin/reservations/report?id=${id}`);

      if (resp.data?.status === "OK") {
        /*dispatch(
          setEtatRemove({
            status: "SUCCESS",
            msg: resp.data.result,
          })
        );*/
        dispatch(setCancelReservationModal(false));
        dispatch(loadEtatsThunk());
      }

      dispatch(setDeleteLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setDeleteLoading(false));
    }
  };
};
